import { useCallback, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { isFunction } from 'lodash';

interface Props {
  onClick?(): void;
}

export const BackButton: FC<Props> = ({ onClick }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (isFunction(onClick)) {
      onClick();
    } else {
      navigate(-1);
    }
  }, [onClick, navigate]);

  return (
    <button onClick={handleClick} type="button">
      <ArrowLeft />
    </button>
  );
};
