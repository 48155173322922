import type { FC } from 'react';
import { BalanceReportCard, TransactionsReportCard } from '../components';

export const ReportsPage: FC = () => (
  <header>
    <h1 className="mb-4 text-4xl text-brand-6 font-bold">Reports</h1>
    <ul className="flex gap-4">
      <TransactionsReportCard />
      <BalanceReportCard />
    </ul>
  </header>
);
