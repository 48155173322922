import { Currency } from "./Currency";

export interface PaymentFormData {
  amount: number;
  currencyInfo: PaymentCurrency;
  expiredIn: string;
  methods: PaymentMethod[];
  type: PaymentFormType;
  canChangeAmount: boolean;
  successUrl: string;
  cancelUrl: string;
  errorUrl: string;
  paymentMethodName?: PaymentMethodName;
  verficationKey?: string
}

export interface PaymentMethod {
  id: number;
  name: PaymentMethodName;
}

export enum PaymentFormType {
  Deposit = "DEPOSIT",
  Withdrawal = "WITHDRAWAL",
}

export enum PaymentMethodName {
  Humo = "HUMOUZS",
  Sberbank = "SBERRUB",
  Sbp = "SBPRUB",
  Tinkoff = "TCSBRUB",
  Uzs = "CARDUZS",
  SberbankXCountry = "SBERRUB_X",
  TinkoffXCountry = "TSCBRUB_X",
}

export interface PaymentCurrency extends Currency {
  id: number;
  maxValue: number;
  minValue: number;
}
