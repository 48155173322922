import classNames from 'classnames';
import { type FC } from 'react';

interface Props {
  className?: string;
  inProgress?: boolean;
  name?: string;
  imageUrl?: string;
}

const BG_COLOR = [`#002147`, `#282c35`, `#551a8b`, `#612302`, `#003366`, `#228b22`, `#808000`];

export const Avatar: FC<Props> = ({ imageUrl, name = '', className = '', inProgress = false }) => {
  const firstLetter = name.toUpperCase().charAt(0);

  return (
    <div
      className={classNames('text-white text-lg font-bold rounded-xl px-4 py-2 w-fit relative', className)}
      style={{
        background: BG_COLOR[Math.floor(Math.random() * BG_COLOR.length)],
        backgroundImage: imageUrl && `url(${imageUrl})`,
      }}
    >
      {name && <span>{firstLetter}</span>}
      {inProgress && <div className="w-2 h-2 bg-brand-9 absolute rounded-full bottom-0 right-0" />}
    </div>
  );
};
