import axios from 'axios';

export interface ChangePasswordParams {
  newPassword: string;
  currentPassword: string;
}

export class UserApi {
  static readonly KEY = 'user';

  static async changePassword(params: ChangePasswordParams): Promise<void> {
    const { data } = await axios.post('/api/v1/user/change_password', params);

    return data.data;
  }
}
