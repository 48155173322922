import axios from 'axios';
import type { Currency, EnumEntity } from '../model';

interface EnrichedCurrency extends Currency {
  id: number;
  maxValue: number;
  minValue: number;
}

export class ReferenceApi {
  static readonly KEY = 'reference';

  static async getById(refHeaderId: number): Promise<EnumEntity[]> {
    const { data } = await axios.get('/api/v1/reference', {
      params: {
        refHeaderId,
      },
    });

    return data.data;
  }

  static async currency(): Promise<EnrichedCurrency[]> {
    const { data } = await axios.get('/api/v1/reference/currency');

    return data.data;
  }
}
