import { Fragment, useState } from 'react';
import type { FunctionComponent, SetStateAction } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

type SelectProps = {
  disabled?: boolean;
  items: { id: string, value: string }[]
  onChange?(value: SetStateAction<{ id: string, value: string }>): void
}

export const Select: FunctionComponent<SelectProps> = ({ items, disabled, onChange }) => {
  const [selected, setSelected] = useState(items[0]);

  const _onChange = (item: typeof selected) => {
    setSelected(item);
    !!onChange && onChange(item);
  };

  return (
    <div className="w-full overflow-visible">
      <Listbox value={items[0]} onChange={_onChange} disabled={disabled}>
        <div className="relative mt-1">
          <Listbox.Button
            className={cx('relative w-[250px] cursor-default rounded-lg bg-white p-2 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm', {
              'bg-blue-900/20': disabled,
            })}>
            <span className="block truncate">{selected.value}</span>
            {!disabled && (
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            )}
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {items.map((item) => (
                <Listbox.Option
                  key={item.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <Fragment>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {item.value}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </Fragment>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};