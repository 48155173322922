export const formatPhoneAsKG = (phoneNumber: string) => {
  if (!phoneNumber.startsWith("+996") || phoneNumber.length !== 13) {
    throw new Error("Invalid phone number format");
  }

  const countryCode = phoneNumber.slice(0, 4); // +996
  const part1 = phoneNumber.slice(4, 7); // XXX
  const part2 = phoneNumber.slice(7); // XXXXXX

  return `${countryCode} ${part1} ${part2}`;
};
