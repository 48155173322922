import { Text } from '@eo-locale/react';
import classNames from 'classnames';
import type { FC } from 'react';
import { PaymentPageLayout } from '../../layouts';
import type { PaymentMethod } from '../../model';
import { getPaymentMethodDesign } from '../../utils';
import { PaymentMethodLogo } from '../atoms';

interface Props {
  methods: PaymentMethod[];

  onChange(method: PaymentMethod): void;
}

export const PaymentFormMethodStep: FC<Props> = ({ methods, onChange }) => {
  return (
    <PaymentPageLayout>
      <header className="mb-7">
        <h1 className="text-brand-6 text-lg text-center font-bold">
          <Text id="form.header.select_method" />
        </h1>
      </header>
      <main className="flex-1 mt-12">
        <ul className="grid grid-cols-2 gap-4">
          {methods.map((method) => (
            <li
              className={classNames('p-4 rounded-2xl text-white text-center', getPaymentMethodDesign(method))}
              key={method.id}
              onClick={() => onChange(method)}
              role="button"
            >
              <PaymentMethodLogo className="mx-auto h-16" method={method} />
            </li>
          ))}
        </ul>
      </main>
    </PaymentPageLayout>
  );
};
