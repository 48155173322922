import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import type { FC } from "react";
import { useParams } from "react-router-dom";
import { CredentialParams, PaymentFormApi } from "../api";
import {
  DepositFail,
  DepositProcessing,
  DepositSuccess,
  PaymentFormStepCredential,
  PaymentFormStepInit,
  Spinner,
} from "../components";
import { PaymentFormMethodStep } from "../components/molecules/PaymentFormMethodStep";
import { PaymentFormType, type PaymentMethod } from "../model";
import { PaymentPageLayout } from "../layouts";

export type Credentials = {
  value: string;
  owner?: string;
  country_code?: "KGS" | "UZS" | "AZN" | "RUB";
  bank_name?: string;
  type?: "by_mobile" | "card_number";
};

enum Step {
  Credential = "Credential",
  Fail = "Fail",
  Init = "Init",
  Method = "Method",
  Processing = "Processing",
  Success = "Success",
}

export const PaymentPage: FC = () => {
  const [method, setMethod] = useState<PaymentMethod>();
  const [step, setStep] = useState<Step>(Step.Fail);
  const { formId = "" } = useParams<{ formId: string }>();
  const { data } = useQuery({
    enabled: Boolean(formId),
    queryFn: () => PaymentFormApi.init(formId!),
    queryKey: [PaymentFormApi.KEY, "init", formId],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSuccess(response) {
      setSuccessUrl(response.successUrl);
      setCancelUrl(response.cancelUrl);
      setErrorUrl(response.errorUrl);
      setStep(response.methods.length > 1 ? Step.Method : Step.Init);
      if (response.paymentMethodName && response.methods.length) {
        const method = response.methods.find(
          (method) => method.name === response.paymentMethodName,
        );
        if (method) {
          setMethod(method);
          requestCredential({
            amount,
            formId,
            paymentMethodId: method.id,
          });
        }
      }
    },
  });

  const [credential, setCredential] = useState({} as Credentials);
  const [successUrl, setSuccessUrl] = useState("");
  const [errorUrl, setErrorUrl] = useState("");
  const [cancelUrl, setCancelUrl] = useState("");
  const [timerExp, setTimerExp] = useState(false);
  const [amount, setAmount] = useState(0);
  const { mutate: requestCredential, variables } = useMutation({
    retryDelay: 1000,
    mutationFn: PaymentFormApi.credential,
    onSuccess(response) {
      setAmount(response.amount);
      setStep(Step.Credential);
      if (data?.type === PaymentFormType.Deposit) {
        if (!response.credentials) {
          setTimeout(() => {
            requestCredential(variables as CredentialParams);
          }, 1000);
          return;
        }
        setCredential(response.credentials);
      }
    },
  });

  const handleChangeMethod = useCallback(
    (newMethod: PaymentMethod) => {
      if (!data) {
        return;
      }

      setMethod(newMethod);

      if (data.canChangeAmount) {
        setStep(Step.Init);
      } else if (
        data.type === PaymentFormType.Deposit ||
        data.type === PaymentFormType.Withdrawal
      ) {
        requestCredential({
          amount,
          formId,
          paymentMethodId: newMethod?.id,
        });
      } else {
        setAmount(data.amount);
        setStep(Step.Credential);
      }
    },
    [amount, data, formId, requestCredential],
  );

  if (!data) {
    return null;
  }

  if (step === Step.Credential) {
    return !!credential.value || data.type === PaymentFormType.Withdrawal ? (
      <PaymentFormStepCredential
        amount={amount}
        credentials={credential}
        data={data}
        formId={formId}
        method={method}
        onBack={() => {
          setStep(data.canChangeAmount ? Step.Init : Step.Method);
        }}
        onSuccess={(newSuccessUrl) => {
          setSuccessUrl(newSuccessUrl);
          setStep(Step.Processing);
        }}
        onError={() => {
          setTimerExp(true);
          setStep(Step.Fail);
        }}
      />
    ) : (
      <PaymentPageLayout>
        <div className="flex flex-center h-full">
          <Spinner className="relative" />
        </div>
      </PaymentPageLayout>
    );
  }

  if (step === Step.Processing) {
    return (
      <DepositProcessing
        amount={amount}
        currency={data.currencyInfo}
        formId={formId}
        onError={() => setStep(Step.Fail)}
        onSuccess={() => setStep(Step.Success)}
      />
    );
  }

  if (step === Step.Success) {
    return (
      <DepositSuccess
        amount={amount}
        currency={data.currencyInfo}
        successUrl={successUrl}
      />
    );
  }

  if (step === Step.Fail) {
    return (
      <DepositFail
        formId={formId}
        redirectUrl={timerExp ? cancelUrl : errorUrl}
      />
    );
  }

  if (step === Step.Method) {
    return (
      <PaymentFormMethodStep
        methods={data.methods}
        onChange={handleChangeMethod}
      />
    );
  }

  if (step === Step.Init) {
    return (
      <PaymentFormStepInit
        data={data}
        formId={formId}
        method={method}
        onBack={() => {
          setStep(Step.Method);
        }}
        onError={() => {
          setStep(Step.Fail);
        }}
        onRequestCredential={(params) => {
          if (data.type === PaymentFormType.Deposit) {
            requestCredential(params);
          } else {
            setAmount(params.amount);
            setStep(Step.Credential);
          }
        }}
      />
    );
  }

  return null;
};
