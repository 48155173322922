import type { FunctionComponent, HTMLAttributes } from 'react';
import cx from 'classnames';
import { Spinner } from './Spinner';

export const Button: FunctionComponent<HTMLAttributes<HTMLButtonElement> & { disabled?: boolean }> = (props) => {
  return (
    <button
      {...props}
      disabled={props.disabled}
      className={cx(
        'flex relative flex-center h-[40px] justify-self-end p-2 w-[105px] text-white active:bg-brand-1/25 rounded-lg text-sm',
        props.disabled ? 'bg-brand-1/10' : 'bg-brand-1',
        props.className
      )}
    >
      {props.disabled ? <Spinner className="size-4" /> : props.children}
    </button>
  );
};