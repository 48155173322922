import { Text } from '@eo-locale/react';
import { type FC } from 'react';
import { PaymentPageLayout } from '../../layouts';
import { PaymentCurrency } from '../../model';
import { Money } from '../atoms';

interface Props {
  amount: number;
  currency: PaymentCurrency;
  successUrl: string;
}

export const DepositSuccess: FC<Props> = ({ amount, currency, successUrl }) => {
  return (
    <PaymentPageLayout>
      <main className="flex-1 flex-center lg:block">
        <div className="w-full pt-[200px] text-center bg-deposit-success">
          <h1 className="mb-2.5 text-4xl font-bold text-brand-6">
            +&nbsp;
            <Money amount={amount} currency={currency} />
          </h1>
          <span className="block text-brand-2 text-sm">
            <Text id="form.text.success" />
          </span>
        </div>
      </main>
      <footer>
        {successUrl ?
          <a className="button button--block button--primary" href={`${successUrl && successUrl.startsWith('http') ? '' : '//'}${successUrl}`}>
            <Text id="form.button.continue" />
          </a>
            : ''}
      </footer>
    </PaymentPageLayout>
  );
};
