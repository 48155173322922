import { Numeric } from '@eo-locale/react';
import type { Currency } from '../../model';
import { normalizeAmount } from '../../utils';

interface MoneyProps {
  amount: number;
  currency: Currency;
}

export const Money: React.FC<MoneyProps> = ({ amount, currency }) => {
  const normalizedAmount = normalizeAmount(amount, currency);
  return (
    <Numeric
      currency={currency.code}
      currencyDisplay="symbol"
      maximumFractionDigits={currency.dimension ?? 0}
      minimumFractionDigits={currency.dimension ?? 0}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      value={normalizedAmount}
    />
  );
};
