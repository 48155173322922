import { Select } from '../../../../components';
import { Fragment, useEffect, useState } from 'react';
import { isChar, isInteger, isNumeric, isString } from '../../lib';
import { CODE_VALUES } from '../../codeValues';
import type { UseFieldArrayAppend, UseFieldArrayRemove, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { EditableTableRowSelect } from './EditableTableRowSelect';
import type { AttrItems, TabsFormData } from '../../typings';
import { AttrType } from '../../../../model';

type EditableTableRowProps = {
  register: UseFormRegister<TabsFormData>
  setValue: UseFormSetValue<TabsFormData>
  remove: UseFieldArrayRemove
  index: number
  attrItems: AttrItems
  append: UseFieldArrayAppend<TabsFormData>
}

const booleanItems = [{ id: '1', value: 'true' }, { id: '0', value: 'false' }];

export const EditableTableRow = ({ index, attrItems, append, remove, setValue, register }: EditableTableRowProps) => {
  const [isValueSelected, setIsValueSelected] = useState(false);
  const [validator, setValidator] = useState<keyof typeof CODE_VALUES>('I');

  useEffect(() => {
    if (validator === 'B') {
      setValue(`attributes.${index}.value`, 'true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validator]);

  const onChange = (value: AttrType) => {
    setValidator(() => value.code);
    setValue(`attributes.${index}.attrType`, value);
    setValue(`attributes.${index}.value`, '');
  };

  const onFirstChange = (value: AttrType) => {
    setValidator(() => value.code);
    setValue(`attributes.${index}.attrType`, value);
    setIsValueSelected(true);
    // @ts-expect-error
    append();
  };

  return (
    <tr className="">
      {isValueSelected
        ? (
          <Fragment>
            <td className="border-b border-slate-700 p-4 relative">
              <EditableTableRowSelect
                setValue={setValue}
                index={index}
                onChange={onChange}
                attrItems={attrItems}
              />
            </td>
            <td className="border-b border-slate-700 p-4">
              {validator !== 'B'
                ? <input
                  className="p-2 border-brand-1 w-full border-2 rounded outline-brand-1"
                  {...register(`attributes.${index}.value`, {
                    required: 'Value is required',
                    minLength: {
                      value: 1,
                      message: 'Value must be at least 1 characters long',
                    },
                    // @ts-expect-error
                    validate:
                      validator === 'I'
                        ? isInteger
                        : validator === 'S'
                          ? isString
                          : validator === 'C'
                            ? isChar
                            : validator === 'N'
                              ? isNumeric
                              : null,
                  })}
                />
                :
                <Select
                  items={booleanItems}
                  // @ts-expect-error
                  onChange={({ value }) => setValue(`attributes.${index}.value`, value)}
                />
              }
            </td>
            <td className="border-b border-slate-700 p-4 relative flex flex-center">
              {CODE_VALUES[validator]}
            </td>
            <td className="border-b border-slate-700 p-4 relative text-center">
              <button
                onClick={() => remove(index)}
                className="flex flex-center justify-self-end p-2 w-full bg-brand-1 text-white active:bg-brand-1/25 rounded-lg text-sm"
              >Unset
              </button>
            </td>
          </Fragment>
        )
        : (
          <Fragment>
            <td className="border-b border-slate-700 p-4 relative">
              <EditableTableRowSelect
                setValue={setValue}
                index={index}
                onChange={onFirstChange}
                attrItems={[{ id: '', value: 'select type' }, ...attrItems]}
              />
            </td>
            <td className="border-b border-slate-700 p-4 relative" />
            <td className="border-b border-slate-700 p-4 relative" />
            <td className="border-b border-slate-700 p-4 relative" />
          </Fragment>
        )
      }
    </tr>
  );
};