import { useQuery } from '@tanstack/react-query';
import { MerchantApi } from '../api';
import type { Merchant } from '../model';
import { useProfile } from './useProfile';

export function useAllMerchants(): Merchant[] {
  const profile = useProfile();
  const hasPermission = Boolean(profile?.permissions.includes('merchant.rw.all'));

  const { data: merchants = [] } = useQuery({
    enabled: hasPermission,
    queryKey: [MerchantApi.KEY, 'getAll'],
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: MerchantApi.getAll,
  });

  return merchants;
}
