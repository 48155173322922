import axios from 'axios';
import type { Balance, Merchant } from '../model';

export class MerchantApi {
  static readonly KEY = 'merchant';

  static async me(): Promise<Merchant> {
    const { data } = await axios.get('/api/v1/merchant/me');

    return data.data;
  }

  static async getAll(): Promise<Merchant[]> {
    const { data } = await axios.get('/api/v1/merchant', {
      params: {
        page: 0,
        size: 50,
      },
    });

    return data.data;
  }

  static async getBalance(terminalId?: string): Promise<Balance> {
    const { data } = await axios.get(`/api/v1/merchant/balance${terminalId ? `?terminalId=${terminalId}` : ''}`);

    return data.data;
  }

  static async getDeposit(terminalId?: string): Promise<Balance> {
    const { data } = await axios.get(`/api/v1/merchant/deposit${terminalId ? `?terminalId=${terminalId}` : ''}`);

    return data.data;
  }

  static async getWithdrawal(terminalId?: string): Promise<Balance> {
    const { data } = await axios.get(`/api/v1/merchant/withdrawal${terminalId ? `?terminalId=${terminalId}` : ''}`);

    return data.data;
  }

  static async saveMerchant({ id, name }: { id: number, name: string }) {
    return await axios.put(`/api/v1/merchant/${id}`, { name });
  }
}
