import axios from 'axios';
import { type PaymentFormData, PaymentFormType, PaymentMethodName } from '../model';
import { add } from 'date-fns';
import { wait } from '../utils';
import { getSupportCode } from '../utils/lib';

export interface CredentialParams {
  amount: number;
  formId: string;
  paymentMethodId?: number;
}

interface CredentialResponse {
  amount: number;
  credentials: {
    after?: string;
    value: string;
  };
}

interface CompleteParams {
  credential: string;
  formId: string;
  bankCodeInteger?: number;
}

interface CompleteResponse {
  successUrl: string;
}

interface StatusReponse {
  status: 'DONE' | 'IN_PROGRESS' | 'DECLINED' | 'CANCELED';
  statusDate: string;
}

const MOCK_CREDENTIAL_RESPONSE: CredentialResponse = {
  credentials: {
    value: '8600424242424242',
  },
  amount: 4000000,
};

const MOCK_PAYMENT_FORM_DATA: PaymentFormData = {
  amount: 4000000,
  methods: [
    {
      id: 2,
      name: PaymentMethodName.Uzs,
    },
    {
      id: 3,
      name: PaymentMethodName.Humo,
    },
    {
      id: 4,
      name: PaymentMethodName.Sberbank,
    },
    {
      id: 5,
      name: PaymentMethodName.Tinkoff,
    },
    {
      id: 6,
      name: PaymentMethodName.Sbp,
    },
  ],
  currencyInfo: {
    id: 3,
    name: 'RUB',
    code: 'RUB',
    dimension: 2,
    minValue: 0,
    maxValue: 9223372036854776000,
  },
  type: PaymentFormType.Withdrawal,
  canChangeAmount: true,
  expiredIn: add(new Date(), {
    hours: 1,
  }).toISOString(),
  successUrl: '',
  cancelUrl: '',
  errorUrl: '',
  paymentMethodName: PaymentMethodName.Sbp,
};

const MOCK_STATUS_RESPONSE: StatusReponse = {
  status: 'IN_PROGRESS',
  statusDate: 'test',
};

const USE_MOCK_DATA = false;
const MOCK_RESPONSE_DELAY = 1_000;

let verficationKey: null | string = null;

export class PaymentFormApi {
  static readonly KEY = 'payment-form';

  static async credential({
                            formId,
                            ...params
                          }: CredentialParams & Partial<AbortController>): Promise<CredentialResponse> {
    if (USE_MOCK_DATA) {
      await wait(MOCK_RESPONSE_DELAY);
      return MOCK_CREDENTIAL_RESPONSE;
    }

    const { data } = await axios.post(`/api/payments/form/${formId}/credential`, {
      ...params,
      supportCode: getSupportCode(`${formId},${verficationKey}`),
    }, {
      signal: params.signal,
    });

    return data.data;
  }

  static async complete(params: CompleteParams): Promise<CompleteResponse> {
    const credential = params.credential.startsWith('+') ? params.credential.substring(1) : params.credential;
    const queryParams: Partial<CompleteParams> = {
      credential,
    };

    if (params.bankCodeInteger) {
      queryParams.bankCodeInteger = params.bankCodeInteger;
    }

    const { data } = await axios.post(`/api/payments/form/${params.formId}/complete`, queryParams);

    return data.data;
  }

  static async init(formId: string): Promise<PaymentFormData> {
    if (USE_MOCK_DATA) {
      await wait(MOCK_RESPONSE_DELAY);
      return MOCK_PAYMENT_FORM_DATA;
    }

    const { data } = await axios.post(`/api/payments/form/${formId}/init`);

    if (!!data?.data?.verficationKey) {
      verficationKey = data.data.verficationKey;
    }

    return data.data;
  }

  static async status(formId: string): Promise<StatusReponse> {
    if (USE_MOCK_DATA) {
      await wait(MOCK_RESPONSE_DELAY);
      return MOCK_STATUS_RESPONSE;
    }

    const { data } = await axios.post(`/api/payments/form/${formId}/status`);

    return data.data;
  }
}
