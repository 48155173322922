import type { FC, PropsWithChildren } from 'react';

export const PaymentPageLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="lg:bg-auth lg:flex-center">
      <div className="relative flex flex-col supports-[height:100cqh]:h-[100cqh] supports-[height:100svh]:h-[100svh] p-5 lg:p-7 lg:w-[520px] lg:min-h-[520px] bg-white lg:rounded-3xl">
        {children}
      </div>
    </div>
  );
};
