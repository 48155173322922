import axios from 'axios';

export interface LoginParams {
  username: string;
  password: string;
}

export class AuthApi {
  static async logIn(params: LoginParams): Promise<any> {
    const { data } = await axios.post('/api/v1/login', params);

    return data;
  }

  static async logOut(): Promise<any> {
    const { data } = await axios.post('/api/v1/logout');

    return data;
  }
}
