import axios from 'axios';
import {Terminal} from "../model";

export class TerminalStatsApi {
    static readonly KEY = 'terminal-stats';

    static async getTerminal(id: number, minutes: number): Promise<{
        terminalId: string
        newCount: number
        successCount: number
        cancelCount: number
        declinedCount: number
        successPercent: number
        cancelPercent: number
        declinedPercent: number
    }[]> {
        const {data} = await axios.get(`/api/data/v1/terminal?minutes=${minutes}&merchantId=${id}`);
        return data.data;
    }

    static async getTerminalsStats(): Promise<{ merchantId: number; name: string; terminals: Terminal[] }[]> {
        const {data} = await axios.get('/api/v1/terminal/statistic');
        return data.data;
    }
}
