import classNames from 'classnames';
import { formatDuration, intervalToDuration, isPast } from 'date-fns';
import { FC, useEffect, useState } from 'react';

interface Props {
  deadline: Date;

  onFinish?(): void;
}

const zeroPad = (value?: number, force = false): string => {
  if (value === undefined) {
    return force ? '00' : '';
  }

  return String(value).padStart(2, '0');
};

export const Timer: FC<Props> = ({ deadline, onFinish }) => {
  const [duration, setDuration] = useState(
    intervalToDuration({
      start: new Date(),
      end: deadline,
    }),
  );

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (isPast(deadline)) {
        onFinish?.();
      } else {
        const newDuration = intervalToDuration({
          start: new Date(),
          end: deadline,
        });

        setDuration(newDuration);
      }
    });

    return () => window.clearInterval(interval);
  }, [deadline, onFinish]);

  if (duration.days) {
    return (
      <span>
        {formatDuration(duration, {
          format: ['days'],
        })}
      </span>
    );
  }

  const items = [zeroPad(duration?.hours), zeroPad(duration?.minutes, true), zeroPad(duration?.seconds, true)];
  const isLastMinute = duration.hours === undefined && duration.minutes === undefined;

  return (
    <button
      className={classNames('flex-1 py-[19px] rounded-r-3xl text-2xl font-semibold border-y-2 border-r-2 border-black', {
        'border-red': isLastMinute,
        'text-red': isLastMinute,
      })}
      type="button"
    >
      {items.filter(Boolean).join(':')}
    </button>
  );
};
