import { useCallback, type FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AuthRoute, Logo } from '../components';
import { useAuthContext } from '../hooks';

interface FormFields {
  username: string;
  password: string;
}

export const LoginPage: FC = () => {
  const { signIn } = useAuthContext();
  const { handleSubmit, register } = useForm<FormFields>();

  const onSubmit: SubmitHandler<FormFields> = useCallback((data) => signIn(data), [signIn]);

  return (
    <AuthRoute>
      <div className="flex-center min-h-screen bg-auth">
        <div className="w-[520px] bg-white px-8 py-10 rounded-3xl">
          <h1 className="mb-3 flex-center">
            <Logo className="text-4xl" />
          </h1>
          <h2 className="mb-9 mx-auto w-[300px] text-sm text-brand-2 text-center">
            Log in to your personal account using your username and password
          </h2>
          <form className="grid gap-8" onSubmit={handleSubmit(onSubmit)}>
            <input
              {...register('username', {
                required: true,
              })}
              className="input"
              placeholder="Login"
              type="text"
            />
            <input
              {...register('password', {
                required: true,
              })}
              autoComplete="current-password"
              className="input"
              placeholder="Password"
              type="password"
            />
            <button className="button button--block button--primary" type="submit">
              Log In
            </button>
          </form>
        </div>
      </div>
    </AuthRoute>
  );
};
