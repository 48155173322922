import { useQuery } from '@tanstack/react-query';
import { ReferenceApi } from '../api';
import { EnumEntity } from '../model';

export function useReference(referenceId: number): EnumEntity[] {
  const { data = [] } = useQuery({
    queryKey: [ReferenceApi.KEY, 'getById', referenceId],
    queryFn: () => ReferenceApi.getById(referenceId),
  });

  return data;
}
