import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { usePaginationRange } from '../../hooks';

interface Props {
  totalItems: number;
  itemsPerPage: number;
  initialPage?: number;
  onPageChange?: (page: number) => void;
  siblingCount?: number;
}

export const Pagination: FC<Props> = ({
  totalItems,
  itemsPerPage,
  initialPage = 1,
  onPageChange = () => {},
  siblingCount = 1,
}) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const paginationRange = usePaginationRange({
    currentPage,
    totalItems,
    itemsPerPage,
    siblingCount,
  });

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  const onClick = async (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      setCurrentPage(pageNumber);
      await onPageChange(pageNumber);
    }
  };

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <ul className="flex gap-4">
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === 0) {
          return <li key={pageNumber + index.toString()}>&#8230;</li>;
        }
        return (
          <li key={pageNumber}>
            <button
              className={classNames(
                'flex-center w-[30px] h-[30px] border border-brand-1 text-xs rounded hover:bg-brand-1 hover:text-white transition-colors',
                {
                  'bg-brand-1 text-white': pageNumber === currentPage,
                },
              )}
              onClick={() => {
                onClick(pageNumber);
              }}
            >
              {pageNumber}
            </button>
          </li>
        );
      })}
    </ul>
  );
};
