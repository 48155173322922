import type { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../config';
import { TagType, type Invoice } from '../../model';
import { Money, Tag } from '../atoms';
import { InvoiceStatus } from './InvoiceStatus';
import {formatDateUTC} from "../../utils/lib";

interface Props {
  invoice: Invoice;
}
export const InvoiceRow: FC<Props> = ({ invoice }) => {
  const navigate = useNavigate();

  return (
    <li
      className="flex items-center p-4 cursor-pointer hover:bg-brand-12 transition-colors rounded-2xl"
      key={invoice.invoiceId}
      onClick={() =>
        navigate(
          generatePath(RoutePath.InvoiceDetails, {
            id: invoice.invoiceId,
          }),
        )
      }
    >
      <div className="flex-1">
        {invoice.invoiceId && <strong className="uppercase font-semibold text-sm">{invoice.invoiceId}</strong>}
        <span className="block capitalize text-xs text-brand-2">
          {formatDateUTC(invoice.createdDate, 'long')}
        </span>
        <span className="block capitalize text-xs text-brand-2">
          {formatDateUTC(invoice.statusDate, 'long')}
        </span>
      </div>
      <div className="flex-1 flex justify-end">
        <div className="text-center">
          <Tag className="capitalize" type={TagType.Neutral}>
            {invoice.flowType.code.toLowerCase()}
          </Tag>
          <br />
          {invoice.isTestProcessing && (
            <Tag className="opacity-50" type={TagType.Neutral}>
              Test
            </Tag>
          )}
        </div>
      </div>
      <div className="flex-1 text-right">
        <InvoiceStatus status={invoice.invoiceStatus} />
      </div>
      <div className="flex-1 text-right font-semibold">
        <Money amount={invoice.amount} currency={invoice.currency} />
      </div>
    </li>
  );
};
