import { useQuery } from '@tanstack/react-query';
import { MerchantApi } from '../api';
import type { Merchant } from '../model';
import { useAllMerchants } from './useAllMerchants';
import { useProfile } from './useProfile';
import { useMerchantStore } from '../store';

export function useMerchant(): Merchant | undefined {
  const merchantId = useMerchantStore((state) => state.merchantId);
  const merchants = useAllMerchants();
  const profile = useProfile();
  const hasPermissionToChooseMerchant = Boolean(profile && profile?.permissions.includes('merchant.read'));

  const { data } = useQuery({
    enabled: hasPermissionToChooseMerchant,
    queryFn: MerchantApi.me,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    queryKey: [MerchantApi.KEY, 'me'],
  });

  if (hasPermissionToChooseMerchant && merchants.length > 0) {
    const merchant = merchants.find((merchant) => merchant.id === merchantId);
    return merchant ?? merchants[0];
  }

  return data;
}
