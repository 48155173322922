import { FC } from 'react';
import { EnumEntity } from '../../model';
import { getTagTypeByInvoiceStatus } from '../../utils';
import { Tag } from '../atoms';

interface Props {
  status: EnumEntity;
}

export const InvoiceStatus: FC<Props> = ({ status }) => {
  return <Tag type={getTagTypeByInvoiceStatus(status)}>{status.value}</Tag>;
};
