import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { useCallback, type FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { UserApi } from '../api';
import { BackButton } from '../components';

interface FormFields {
  confirmNewPassword: string;
  newPassword: string;
  oldPassword: string;
}

export const ChangePasswordPage: FC = () => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormFields>({
    defaultValues: {},
  });
  const { mutate } = useMutation({
    mutationFn: UserApi.changePassword,
    onSuccess() {
      toast.success('Password changed successfully!');
      reset();
    },
  });

  const onSubmit: SubmitHandler<FormFields> = useCallback(
    (data) =>
      mutate({
        newPassword: data.newPassword,
        currentPassword: data.oldPassword,
      }),
    [mutate],
  );

  return (
    <>
      <h1 className="mb-4 text-4xl text-brand-6 font-bold">Settings</h1>
      <div className="p-7 w-3/5 bg-white rounded-3xl">
        <div className="mb-8 flex items-center gap-5">
          <BackButton />
          <h2 className="text-lg font-bold">Change password</h2>
        </div>
        <form className="grid gap-5" onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register('oldPassword', {
              required: true,
            })}
            className={classNames('input', {
              'input--invalid': Boolean(errors.oldPassword),
            })}
            placeholder="Enter the old password"
            type="password"
          />
          <input
            {...register('newPassword', {
              required: true,
            })}
            className={classNames('input', {
              'input--invalid': Boolean(errors.newPassword),
            })}
            placeholder="New password"
            type="password"
          />
          <input
            {...register('confirmNewPassword', {
              required: true,
              validate: (value, formValues) => value === formValues.newPassword,
            })}
            className={classNames('input', {
              'input--invalid': Boolean(errors.confirmNewPassword),
            })}
            placeholder="Confirm your password"
            type="password"
          />
          <button className="button button--block button--primary" type="submit">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};
