import axios from 'axios';
import type { SupportIssue } from '../model';

export class SupportApi {
  static readonly KEY = 'support';

  static async getIssuesByInvoice(invoiceId: string): Promise<SupportIssue[]> {
    const { data } = await axios.get(`/api/v1/merchant/support/${invoiceId}`);

    return data.data;
  }
}
