import { Text } from "@eo-locale/react";
import classNames from "classnames";
import type { FC } from "react";
import type { PaymentCurrency, PaymentMethod } from "../../model";
import { getPaymentMethodDesign, normalizeAmount } from "../../utils";
import { Money, PaymentMethodLogo } from "../atoms";
import { CopyButton } from "./CopyButton";
import ru from "../../assets/images/ru.png";
import az from "../../assets/images/az.png";
import uz from "../../assets/images/uz.png";
import kgs from "../../assets/images/kgs.png";
import type { Credentials } from "../../pages/PaymentPage";
import {
  formatPhoneAsAZ,
  formatPhoneAsKG,
  formatPhoneAsUZ,
  formatPhoneAsRU,
} from "../../utils/lib";
import cx from 'classnames';

interface Props {
  amount: number;
  cardNumber: string;
  currency: PaymentCurrency;
  method: PaymentMethod;
  mode?: "input" | "read-only";
  owner?: Credentials["owner"];
  countryCode?: Credentials["country_code"];
  bankName?: Credentials["bank_name"];
  type?: Credentials["type"];

  onChange?(value: string): void;
}

const countryImageSwitcher = {
  KGS: kgs,
  UZS: uz,
  AZN: az,
  RUB: ru,
};

const countryPhoneFormatter = {
  KGS: formatPhoneAsKG,
  UZS: formatPhoneAsUZ,
  AZN: formatPhoneAsAZ,
  RUB: formatPhoneAsRU,
};

export const CreditCard: FC<Props> = ({
  amount,
  cardNumber,
  currency,
  method,
  mode = "read-only",
  bankName,
  countryCode,
  owner,
  onChange,
  type,
}) => {
  const formattedPhone =
    countryCode &&
    type === "by_mobile" &&
    countryPhoneFormatter[countryCode](`+${cardNumber}`);

  return (
    <div
      className={classNames("p-8 rounded-xl", getPaymentMethodDesign(method))}
    >
      <div className="flex justify-between items-center mb-5">
        <PaymentMethodLogo className="h-12" method={method} />
        {bankName && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="size-20"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
            />
          </svg>
        )}
        <span className="grid justify-items-center">
          <img
            alt="country"
            className={cx("h-12", {
              'rotate-90': !countryCode && !bankName
            })}
            src={
              countryCode
                ? countryImageSwitcher[countryCode]
                : require("../../assets/images/credit-card-chip.svg").default
            }
          />
          {bankName && (
            <span className="text-xs whitespace-nowrap font-bold mt-1">
              {bankName}
            </span>
          )}
        </span>
      </div>
      <div className="mb-1">
        <span className="block opacity-50 text-sm">
          {formattedPhone ? (
            <Text id="form.field.phoneNumber" />
          ) : (
            <Text id="form.field.cardNumber" />
          )}
        </span>
        <div className="flex items-center gap-4">
          {mode === "read-only" ? (
            <>
              <strong className="font-semibold">
                {formattedPhone
                  ? formattedPhone
                  : cardNumber.replace(/(\d{4})/g, "$1 ")}
              </strong>
              <CopyButton value={cardNumber} />
            </>
          ) : (
            <input
              className="mt-1 input rounded-md p-2 w-full"
              onChange={(event) => onChange?.(event.target.value)}
              type="tel"
            />
          )}
        </div>
      </div>
      {owner && (
        <div className="mb-1">
          <span className="block opacity-50 text-sm">
            <Text id="form.field.owner" />
          </span>
          <div className="flex gap-4">
            <strong className="font-semibold">{owner}</strong>
          </div>
        </div>
      )}
      <div>
        <span className="block opacity-50 text-sm">
          <Text id="form.field.amount" />
        </span>
        <div className="flex gap-4">
          <strong className="font-semibold">
            <Money amount={amount} currency={currency} />
          </strong>
          <CopyButton value={String(normalizeAmount(amount, currency))} />
        </div>
      </div>
    </div>
  );
};
