import { Spinner } from "../../../../components";
import { useQuery } from "@tanstack/react-query";
import type {
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { EditableTableRows } from "./EditableTableRows";
import type { AttrItems, TabsFormData } from "../../typings";
import type { AttrType, TerminalMerchantProps } from "../../../../model";
import { TableRow } from "./TableRow";
import { CODE_VALUES } from "../../codeValues";
import { WithData } from "../../../../utils/lib";

type TerminalTableProps = WithData<TerminalMerchantProps> & {
  terminalId: string;
  register: UseFormRegister<TabsFormData>;
  setValue: UseFormSetValue<TabsFormData>;
  fields: FieldArrayWithId<TabsFormData>[];
  remove: UseFieldArrayRemove;
  errors: FieldErrors;
  append: UseFieldArrayAppend<TabsFormData>;
};

export const EditableTable = ({
  data,
  errors,
  remove,
  terminalId,
  append,
  register,
  setValue,
  fields,
}: TerminalTableProps) => {
  const { data: attributes } = useQuery<AttrType[]>({
    queryKey: ["attributes"],
    queryFn: () =>
      fetch("/api/v1/reference?refHeaderId=105")
        .then((r) => r.json())
        .then(({ data }) => data),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const attrItems =
    attributes &&
    attributes.map((item) => ({
      ...item,
      value: item.value.split(".").at(-1),
    }));

  if (!attrItems) return <Spinner />;

  return (
    <table className="border-collapse w-full text-sm bg-white relative">
      <thead>
        <tr>
          <th className="sticky z-[100] bg-white w-[150px] top-0 border-b border-slate-700 py-4 text-slate-500 dark:text-slate-400">
            Attribute type
          </th>
          <th className="sticky bg-white top-0 border-b border-slate-700 py-4 text-slate-500 dark:text-slate-400">
            Value
          </th>
          <th className="sticky bg-white top-0 border-b border-slate-700 py-4 text-slate-500 dark:text-slate-400">
            Parameter type
          </th>
          <th className="sticky z-[100] bg-white top-0 border-b border-slate-700 py-4 text-slate-500 dark:text-slate-400">
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y">
        {data.attributes.map(({ id, value, attrType, paramType }) => {
          if (attrType) {
            attrType = {
              ...attrType,
              value: attrType.value.split(".").at(-1) as string,
            };
          }

          return (
            <TableRow
              key={Math.random()}
              id={id}
              terminalId={terminalId}
              value={value}
              code={paramType.code as keyof typeof CODE_VALUES}
              items={[attrType] ?? attrItems}
            />
          );
        })}

        <EditableTableRows
          attrItems={attrItems as AttrItems}
          fields={fields}
          register={register}
          setValue={setValue}
          append={append}
          remove={remove}
          errors={errors}
        />
      </tbody>
    </table>
  );
};
