import { useQuery } from '@tanstack/react-query';
import { useMerchant } from '../../hooks';
import type { TerminalsByMerchant } from '../../model';
import { Fragment } from 'react';
import { TerminalApi } from '../../api';
import { TerminalTabs } from './ui/TerminalTabs';
import { Spinner } from '../../components';

export const TerminalsPage = () => {
  const merchant = useMerchant();
  const id = merchant && merchant.id;
  const { data } = useQuery<TerminalsByMerchant>({
    queryKey: [TerminalApi.KEY, 'terminalsByMerchant', id],
    queryFn: () => TerminalApi.getTerminalByMerchantId(id as unknown as string),
  });

  return (
    <Fragment>
      <h1 className="mb-4 text-4xl text-brand-6 font-bold">Terminals</h1>
      <div className="p-7 w-4/5 bg-white rounded-3xl relative">
        {data
          ? <TerminalTabs data={data.map(({ id, name }) => ({ id, name }))} />
          : <Spinner />
        }
      </div>
    </Fragment>
  );
};

