import { type FC } from 'react';
import user from '../../assets/images/user-logo.svg';
import { useProfile } from '../../hooks';
import { TerminalSelector } from '../molecules';
import { RoutePath } from '../../config';
import { useLocation } from 'react-router-dom';
import {CurrentTime} from "./currentTime";

export const Header: FC = () => {
  const profile = useProfile();
  const { pathname } = useLocation();

  const isTerminalSelectorVisible = pathname === RoutePath.Invoices || pathname === RoutePath.Reports;

  return (
    <div className="mb-10 flex justify-between">
      <div className="flex items-center gap-4">
        <img src={user} alt="user" width={40} height={40} />
          <div className="flex flex-col">
              <span className="text-brand-6 font-semibold">
                  {profile?.personal.firstName}&nbsp;{profile?.personal.lastName}
              </span>
              <small><CurrentTime /></small>
          </div>
      </div>
      <div className="flex gap-4">
        {isTerminalSelectorVisible && <TerminalSelector />}
      </div>
    </div>
  );
};
