import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { FC, PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '../../api';
import { RoutePath } from '../../config';
import { AuthContext } from '../../model';
import { toast } from 'react-toastify';

const AUTH_COOKIE_KEY = 'jwt';

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [authorized, setAuthorized] = useState(Boolean(Cookies.get(AUTH_COOKIE_KEY)));
  const { mutate: signOut } = useMutation({
    mutationFn: AuthApi.logOut,
    onSettled() {
      setAuthorized(false);
      navigate(RoutePath.Login);
      queryClient.clear();
      Cookies.remove(AUTH_COOKIE_KEY);
    },
  });
  const { mutate: signIn } = useMutation({
    mutationFn: AuthApi.logIn,
    onSuccess() {
      setAuthorized(true);
    },
    onError({ response }) {
      if(response.status === 401) {
        toast.error(response.statusText);
      }
    }
  });

  return <AuthContext.Provider value={{ isAuthorized: authorized, signIn, signOut }}>{children}</AuthContext.Provider>;
};
