import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../assets/icons/arrow-right.svg';
import { RoutePath } from '../config';

export const SettingsPage: FC = () => {
  return (
    <>
      <h1 className="mb-4 text-4xl text-brand-6 font-bold">Settings</h1>
      <div className="p-7 w-3/5 bg-white rounded-3xl">
        <Link className="flex justify-between items-center gap-5" to={RoutePath.ChangePassword}>
          Change password
          <ArrowRight />
        </Link>
      </div>
    </>
  );
};
