import type { FC } from 'react';
import { ReactComponent as PhoneWalletIcon } from '../../assets/icons/phone-wallet.svg';
import { PaymentMethod, PaymentMethodName } from '../../model';
import worldImg from '../../assets/images/world-icon.png';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  method?: PaymentMethod;
}

function getLogoSrc(method: PaymentMethod): string | undefined {
  switch (method.name) {
    case PaymentMethodName.Humo:
      return require('../../assets/images/humo.png');
    case PaymentMethodName.Uzs:
      return require('../../assets/images/uzcard.svg').default;
    case PaymentMethodName.Sberbank:
      return require('../../assets/images/sber.svg').default;
    case PaymentMethodName.Tinkoff:
      return require('../../assets/images/tinkoff.svg').default;
    case PaymentMethodName.SberbankXCountry:
      return require('../../assets/images/sber.svg').default;
    case PaymentMethodName.TinkoffXCountry:
      return require('../../assets/images/tinkoff.svg').default;
    case PaymentMethodName.Sbp:
      return require('../../assets/images/sbp.svg').default;
  }
}

export const PaymentMethodLogo: FC<Props> = ({ method, ...rest }) => {
  if (!method) {
    return <PhoneWalletIcon />;
  }

  return (
    <span className="relative">
      <img alt="Payment Method Logo" src={getLogoSrc(method)} {...rest} />
      {
        (method.name === PaymentMethodName.SberbankXCountry || method.name === PaymentMethodName.TinkoffXCountry)
        && <img className="absolute w-[15px] h-[15px] right-0 bottom-0" src={worldImg} alt="world-icon" />
      }
    </span>
  );
};
