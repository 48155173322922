import { Fragment, memo, useEffect } from 'react';
import type {
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { EditableTableRow } from './EditableTableRow';
import type { AttrItems, TabsFormData } from '../../typings';

type EditableTableRowProps = {
  register: UseFormRegister<TabsFormData>
  setValue: UseFormSetValue<TabsFormData>
  fields: FieldArrayWithId<TabsFormData>[]
  append: UseFieldArrayAppend<TabsFormData>
  remove: UseFieldArrayRemove
  errors: FieldErrors
  attrItems: AttrItems
}

export const EditableTableRows = memo(({
                                         remove,
                                         append,
                                         attrItems,
                                         register,
                                         errors,
                                         setValue,
                                         fields,
                                       }: EditableTableRowProps) => {
  useEffect(() => {
    // @ts-expect-error
    append();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {fields.map((field, index) => {
        return (
          <Fragment key={field.id}>
            <EditableTableRow
              index={index}
              remove={remove}
              append={append}
              attrItems={attrItems}
              register={register}
              setValue={setValue}
            />
            {/*@ts-expect-error*/}
            {errors.attributes && errors.attributes[index] && (
              <tr>
                <td/>
                {/*@ts-expect-error*/}
                <td colSpan={20} className="text-rose-600 text-center">{errors.attributes[index].value.message}</td>
              </tr>
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
});