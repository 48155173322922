import { useMerchant } from '../../hooks';
import { Fragment } from 'react';
import { Spinner } from '../../components';
import { MerchantForm } from './ui/MerchantForm';

export const MerchantPage = () => {
  const merchant = useMerchant();

  return (
    <Fragment>
      <h1 className="mb-4 text-4xl text-brand-6 font-bold">Merchant</h1>
      <div className="p-7 w-4/5 bg-white rounded-3xl h-full relative">
        {merchant
          ? <MerchantForm merchant={merchant} />
          : <Spinner />
        }
      </div>
    </Fragment>
  );
};

