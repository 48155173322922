export const formatPhoneAsAZ = (phoneNumber: string) => {
  if (!phoneNumber.startsWith("+994") || phoneNumber.length !== 13) {
    throw new Error("Invalid phone number format");
  }

  const countryCode = phoneNumber.slice(0, 4); // +994
  const part1 = phoneNumber.slice(4, 6); // 36
  const part2 = phoneNumber.slice(6, 9); // 554
  const part3 = phoneNumber.slice(9, 11); // 74
  const part4 = phoneNumber.slice(11); // 21

  return `${countryCode} ${part1} ${part2} ${part3} ${part4}`;
};
