import type { TabsFormData } from '../pages/TerminalPage/typings';
import axios from 'axios';
import type { TerminalMerchantProps } from '../model';

type TerminalProps = Pick<TerminalMerchantProps, 'id' | 'name' | 'paymentFlow'>

export class TerminalApi {
  static readonly KEY = 'terminal-key';

  static async getTerminals(): Promise<TerminalProps[]> {
    const { data } = await fetch(`/api/v1/terminal`).then((r) => r.json());
    return data;
  }

  static async getTerminalByMerchantId(id: string) {
    const { data } = await fetch(`/api/v1/terminal?${id ? `?merchantId=${id}` : ''}`).then((r) => r.json());
    return data;
  }

  static async getTerminal(id: string) {
    const { data } = await fetch(`/api/v1/terminal/${id}`).then((r) => r.json());
    return data;
  }

  static async switchTerminalMode(id: string) {
    return await axios.patch(`/api/v1/terminal/${id}`);
  }

  static async saveTerminal({ id, data }: { id: string, data: TabsFormData }) {
    return await axios.put(`/api/v1/terminal/${id}`, data);
  }

  static async removeAttribute(data: {
    terminalId: string,
    attributeId: string,
  }) {
    return await axios.delete(`/api/v1/terminal/${data.terminalId}?attributeId=${data.attributeId}`)
  }
}