export enum RoutePath {
  ChangePassword = '/settings/change-password',
  Home = '/home',
  InvoiceDetails = '/invoices/:id',
  Invoices = '/invoices',
  Login = '/login',
  Notifications = '/notifications',
  Payment = '/payment/:formId',
  Reports = '/reports',
  Merchant = '/merchant',
  Settings = '/settings',
  Support = '/support',
  Terminals = '/terminals',
  Monitoring = '/monitoring',
}
