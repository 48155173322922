import { EditableTable } from "../EditableTable";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { TabsFormData } from "../../typings";
import { TerminalApi } from "../../../../api";
import { Button, Spinner, ConfirmDialog, Switch } from "../../../../components";
import { toast } from "react-toastify";
import { Fragment, useState } from "react";
import { useProfile } from "../../../../hooks";

type TabFormProps = { id: string };

export const TabForm = ({ id }: TabFormProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<TabsFormData>({
    mode: "onSubmit",
  });

  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: [TerminalApi.KEY, id],
    queryFn: () => TerminalApi.getTerminal(id),
  });

  const profile = useProfile();
  const [checked, setChecked] = useState(
    data && data.isTest ? data.inTest : false,
  );
  const [isOpen, setIsOpen] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attributes",
  });

  const onCheckMutation = useMutation({
    mutationFn: () => TerminalApi.switchTerminalMode(id),
    onSuccess: () => {
      queryClient.refetchQueries([TerminalApi.KEY, id]);
      remove(0);
    },
  });

  const onSaveMutation = useMutation({
    mutationFn: TerminalApi.saveTerminal,
    onSuccess: () => {
      queryClient.refetchQueries([TerminalApi.KEY, id]);
      fields.length > 1 && remove(0);
    },
    onError: ({ response: { data } }) => {
      data["Message:"] && toast.error(data["Message:"]);
    },
  });

  const isSwitchVisible = profile?.permissions.includes("terminal.prod.change");
  const isTerminalAttrVisible = profile?.permissions.includes("terminal.attr.read");

  const onSwitchClick = () => {
    setIsOpen(true);
  };

  const onContinue = () => {
    setChecked(true);
    setIsOpen(false);
    onCheckMutation.mutate();
  };

  const onSubmit = (formData: TabsFormData) => {
    const attributes = formData.attributes
      .filter((item) => !!item.value)
      .map(({ attrType: { id }, value }) => ({
        value,
        attrType: { id },
      }));

    onSaveMutation.mutate({
      id: data.id,
      data: {
        ...formData,
        attributes: attributes as TabsFormData["attributes"],
      },
    });
  };

  return (
    <Fragment>
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        {data ? (
          <div key={data.id} className="text-xs text-brand grid">
            <div className="flex gap-4 mb-5 justify-between">
              <div className="flex flex-center">
                <span className="w-40 text-brand-2">ID</span>
                <span>{data.id}</span>
              </div>
              <div className="flex flex-col">
                {isSwitchVisible && (
                  <Switch checked={checked} onClick={onSwitchClick} />
                )}
              </div>
            </div>
            <div className="flex gap-4 mb-5">
              <span className="w-40 text-brand-2">Name</span>
              <input
                defaultValue={data.name}
                {...register("name", {
                  required: "Name is required",
                  minLength: {
                    value: 3,
                    message: "Name must be at least 3 characters long",
                  },
                })}
                className="p-1 border-brand-1 border-2 rounded outline-none"
              />
            </div>
            <div className="flex gap-4 mb-5">
              <span className="w-40 text-brand-2">Flow Type</span>
              <span>{data.paymentFlow}</span>
            </div>
            <div className="flex gap-4 mb-5">
              <span className="w-40 text-brand-2">Currency</span>
              <span>{data.currency.code}</span>
            </div>
            {isTerminalAttrVisible && (
              <div className="relative rounded-xl overflow-x-visible">
                <EditableTable
                  data={data}
                  terminalId={data.id}
                  register={register}
                  setValue={setValue}
                  append={append}
                  fields={fields}
                  remove={remove}
                  errors={errors}
                />
              </div>
            )}
            {errors && errors && errors['name'] && (
              <span>{errors['name'].message}</span>
            )}
          </div>
        ) : (
          <Spinner />
        )}
        <div className="mt-2 flex gap-2">
          <Button>
            {onSaveMutation.isLoading ? (
              <Spinner className="text-white" />
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </form>
      <ConfirmDialog
        isOpen={isOpen}
        onClick={() => setIsOpen(false)}
        onContinue={onContinue}
        title={"State changing"}
        paragraph={
          "Are you sure to switch current terminal to production state?"
        }
      />
    </Fragment>
  );
};
