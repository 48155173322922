import { useCallback, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { LuCheck } from 'react-icons/lu';
import { GetInvoicesFilter } from '../../api';
import { Reference } from '../../config';
import { useReference } from '../../hooks';
import { formatDateForInput, getTagTypeByInvoiceStatus } from '../../utils';
import { Tag } from '../atoms';

interface Props {
  value?: GetInvoicesFilter;

  onChange(newValue: GetInvoicesFilter): void;
}

export const InvoicesFilterForm: FC<Props> = ({ onChange, value }) => {
  const flowTypeList = useReference(Reference.FlowType);
  const invoiceStatusList = useReference(Reference.InvoiceStatus);
  const { handleSubmit, register, reset, watch } = useForm<GetInvoicesFilter>({
    defaultValues: {
      ...value,
      dateFrom: formatDateForInput(value?.dateFrom),
      dateTo: formatDateForInput(value?.dateTo),
      flowType: value?.flowType ?? null,
      lastStatusDate: formatDateForInput(value?.lastStatusDate),
      statusIds: value?.statusIds ?? [],
    },
  });
  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');
  const sumFrom = watch('sumFrom');
  const sumTo = watch('sumTo');

  const handleResetClick = useCallback(() => {
    reset({
      dateFrom: null,
      dateTo: null,
      flowType: null,
      lastStatusDate: null,
      statusIds: invoiceStatusList?.map(() => false as any) ?? [],
      sumFrom: null,
      sumTo: null,
    });
  }, [reset, invoiceStatusList]);

  const onSubmit = useCallback(
    (data: GetInvoicesFilter) => {
      const newFilter: GetInvoicesFilter = {};
      for (const [key, value] of Object.entries(data)) {
        if (value instanceof Date) {
          if (!Number.isNaN(value.getTime())) {
            (newFilter as any)[key] = value;
          }
        } else if (!Number.isNaN(value)) {
          (newFilter as any)[key] = value;
        }
      }
      onChange(newFilter);
    },
    [onChange],
  );

  return (
    <form className="grid gap-4 p-8" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <span className="mb-1 block text-brand-2 text-sm pl-4">Status</span>
        <div className="flex gap-4">
          {invoiceStatusList.map((status, index) => (
            <label className="group flex gap-2 select-none" key={status.id}>
              <input
                className="hidden"
                {...register(`statusIds.${index}` as any)}
                type="checkbox"
                value={status.id.toString()}
              />
              <Tag
                className="flex items-center gap-1 cursor-pointer text-base px-2 py-1"
                type={getTagTypeByInvoiceStatus(status)}
              >
                <LuCheck className="hidden group-has-[:checked]:block" />
                {status.value}
              </Tag>
            </label>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <label>
          <span className="mb-1 block text-brand-2 text-sm pl-4">Date From</span>
          <input
            {...register('dateFrom', {
              valueAsDate: true,
            })}
            className="input w-full"
            max={formatDateForInput(dateTo)}
            type="date"
          />
        </label>
        <label>
          <span className="mb-1 block text-brand-2 text-sm pl-4">Date To</span>
          <input
            {...register('dateTo', {
              valueAsDate: true,
            })}
            className="input w-full"
            min={formatDateForInput(dateFrom)}
            type="date"
          />
        </label>
        <label>
          <span className="mb-1 block text-brand-2 text-sm pl-4">Last Status Date</span>

          <input
            {...register('lastStatusDate', {
              valueAsDate: true,
            })}
            className="input w-full"
            type="date"
          />
        </label>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <label>
          <span className="mb-1 block text-brand-2 text-sm pl-4">Flow Type</span>
          <select {...register('flowType')} className="input appearance-none w-full">
            <option value="">Not selected</option>
            {flowTypeList.map((option) => (
              <option key={option.code} value={option.code}>
                {option.value}
              </option>
            ))}
          </select>
        </label>
        <label>
          <span className="mb-1 block text-brand-2 text-sm pl-4">Sum From</span>

          <input
            {...register('sumFrom', {
              valueAsNumber: true,
            })}
            className="input w-full"
            max={sumTo?.toString()}
            placeholder="0"
            type="number"
          />
        </label>
        <label>
          <span className="mb-1 block text-brand-2 text-sm pl-4">Sum To</span>

          <input
            {...register('sumTo', {
              valueAsNumber: true,
            })}
            className="input w-full"
            min={sumFrom?.toString()}
            placeholder="0"
            type="number"
          />
        </label>
      </div>

      <footer className="mt-8 grid grid-cols-2 gap-4">
        <button className="px-8 button" onClick={handleResetClick} type="button">
          Reset Filter
        </button>
        <button className="px-8 button button--primary" type="submit">
          Apply Filter
        </button>
      </footer>
    </form>
  );
};
