import { Text } from '@eo-locale/react';
import { type FC } from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import { useInterval } from 'usehooks-ts';
import { PaymentFormApi } from '../../api';
import { PaymentPageLayout } from '../../layouts';
import { PaymentCurrency } from '../../model';
import { Money } from '../atoms';

interface Props {
  amount: number;
  currency: PaymentCurrency;
  formId: string;

  onError(): void;
  onSuccess(): void;
}

const REQUEST_DELAY = 5_000; // 5 seconds

export const DepositProcessing: FC<Props> = ({ amount, currency, formId, onError, onSuccess }) => {
  useInterval(async () => {
    const { status } = await PaymentFormApi.status(formId);

    if (status === 'DONE') {
      onSuccess();
    }

    if (status === 'CANCELED' || status === 'DECLINED') {
      onError();
    }
  }, REQUEST_DELAY);

  return (
    <PaymentPageLayout>
      <main className="flex-1 flex-center lg:block">
        <div className="w-full pt-[200px] text-center">
          <h1 className="mb-2.5 text-4xl font-bold text-brand-6">
            +&nbsp;
            <Money amount={amount} currency={currency} />
          </h1>
          <span className="block text-brand-2 text-sm">
            <Text id="form.text.processing" />
          </span>
          <div className="mt-4 flex-center">
            <FadeLoader color="#8375E9" />
          </div>
        </div>
      </main>
    </PaymentPageLayout>
  );
};
