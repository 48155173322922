import { format, isValid } from 'date-fns';
import { Currency, EnumEntity, PaymentMethod, PaymentMethodName, TagType } from '../model';

// returns css class name that contains background and text color
export function getPaymentMethodDesign(method?: PaymentMethod): string {
  if (!method) {
    return '';
  }

  switch (method.name) {
    case PaymentMethodName.Humo:
      return 'bg-payment-humo text-white';
    case PaymentMethodName.Uzs:
      return 'bg-payment-uzcard text-white';
    case PaymentMethodName.Sberbank:
      return 'bg-payment-sber text-white';
    case PaymentMethodName.Tinkoff:
      return 'bg-payment-tinkoff text-[#333333]';
    case PaymentMethodName.SberbankXCountry:
      return 'bg-payment-sber text-white';
    case PaymentMethodName.TinkoffXCountry:
      return 'bg-payment-tinkoff text-[#333333]';
    case PaymentMethodName.Sbp:
      return 'bg-payment-sbp text-black';
  }
}

export function formatDateForInput(value?: Date | null): any {
  return isValid(value) ? format(value!, 'yyyy-MM-dd') : null;
}

export function normalizeAmount(amount: number, currency: Currency): number {
  if (currency.dimension === 0) {
    return amount;
  }

  const original = String(amount);
  const fraction = original.slice(-currency.dimension);
  const integer = original.slice(0, original.length - currency.dimension);

  return parseFloat(`${integer}.${fraction}`);
}

export function formatMoney(amount: number, currency: Currency, locale: string): string {
  return normalizeAmount(amount, currency).toLocaleString(locale, {
    currency: currency.code,
    currencyDisplay: "",
    maximumFractionDigits: currency.dimension ?? 0,
    minimumFractionDigits: currency.dimension ?? 0,
    style: 'currency',
  });
}

export function getTagTypeByInvoiceStatus({ code }: EnumEntity): TagType {
  if (code === 'DECLINED' || code === 'CANCELED') {
    return TagType.Error;
  }

  if (code === 'DONE' || code === 'PAYED') {
    return TagType.Success;
  }

  if (code === 'NEW') {
    return TagType.Neutral;
  }

  if (code === 'IN_PROGRESS') {
    return TagType.Primary;
  }

  return TagType.Warning;
}

export async function wait(duration: number): Promise<void> {
  return new Promise((resolve) => window.setTimeout(resolve, duration));
}