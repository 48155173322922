import { Text, useTranslator } from '@eo-locale/react';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { BackButton, CopyButton, CreditCard, Money, PaymentMethodLogo, Timer } from '..';
import { PaymentFormApi } from '../../api';
import walletIcon from '../../assets/icons/wallet.png';
import { PaymentPageLayout } from '../../layouts';
import { PaymentFormData, PaymentFormType, PaymentMethod, PaymentMethodName } from '../../model';
import { formatMoney, getPaymentMethodDesign, normalizeAmount } from '../../utils';
import { ChangeEvent, FC, Fragment, useRef, useState } from 'react';
import type { Credentials } from '../../pages/PaymentPage';
import { PaymentFormBankSelector } from './PaymentFormBankSelector';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

interface Props {
  data: PaymentFormData;
  amount: number;
  credentials: Credentials;
  formId: string;
  method?: PaymentMethod;

  onBack(): void;

  onError(): void;

  onSuccess(successUrl: string): void;
}

const CARD_PAYMENT_METHODS: ReadonlyArray<PaymentMethodName> = [
  PaymentMethodName.Humo,
  PaymentMethodName.Uzs,
  PaymentMethodName.Tinkoff,
  PaymentMethodName.TinkoffXCountry,
  PaymentMethodName.Sberbank,
  PaymentMethodName.SberbankXCountry,
];

const formatCredential = (
  credential: string,
  method?: PaymentMethod,
): string =>
  method?.name === PaymentMethodName.Sbp ||
  method?.name === PaymentMethodName.SberbankXCountry ||
  method?.name === PaymentMethodName.TinkoffXCountry
    ? `+${credential}`
    : credential;

export const PaymentFormStepCredential: FC<Props> = ({
                                                       data,
                                                       amount,
                                                       credentials,
                                                       formId,
                                                       method,
                                                       onBack,
                                                       onError,
                                                       onSuccess,
                                                     }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [credential, setCredential] = useState(credentials.value ?? '');
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [bankCodeInteger, setBankCodeInteger] = useState(0);
  const translator = useTranslator();

  const { mutate } = useMutation({
    mutationFn: PaymentFormApi.complete,
    onSuccess(response) {
      onSuccess(response.successUrl);
    },
    onError() {
      onError();
    },
  });

  const onBankSelect = (bankInteger: number) => {
    setBankCodeInteger(bankInteger);
  };

  const depositSuccess = () => {
    if (isBankSelectorVisible) {
      if (!bankCodeInteger) {
        toast.error('Choose the bank');
        return;
      }

      if (credential.trim().length < 9) {
        toast.error('Fill in the phone number');
        ref.current && ref.current.focus();
        return;
      }

      mutate({
        credential,
        formId,
        bankCodeInteger,
      });

      return;
    }

    mutate({
      credential,
      formId,
    });
  };

  const isBackVisible = data.canChangeAmount || data.methods.length > 1;
  const isDeposit = data.type === PaymentFormType.Deposit;
  const isSberX = method?.name === PaymentMethodName.SberbankXCountry;
  const isTinkoffX = method?.name === PaymentMethodName.TinkoffXCountry;
  const isSbp = method?.name === PaymentMethodName.Sbp;
  const isPhoneNumberField = isSbp || isSberX || isTinkoffX;
  const isBankSelectorVisible = data.type === PaymentFormType.Withdrawal && isSbp

  return (
    <PaymentPageLayout>
      <Fragment>
        <header className="mb-7 flex items-center justify-between">
          {isBackVisible ? (
            data.currencyInfo.code !== 'RUB' ? (
              <BackButton onClick={onBack} />
            ) : (
              ''
            )
          ) : (
            <span>&nbsp;</span>
          )}
          <h1 className="text-brand-6 text-lg text-center font-bold">
            <Text
              id={isDeposit ? 'form.header.deposit' : 'form.header.withdrawal'}
              amount={formatMoney(
                amount,
                data.currencyInfo,
                translator.language,
              )}
            />
          </h1>
          <span>&nbsp;</span>
        </header>
        <main className="flex-1">
          <div>
            <h2 className="mb-7 font-bold uppercase text-brand-6 text-sm text-wrap whitespace-pre">
              <Text
                id={
                  isDeposit
                    ? isSberX
                      ? 'form.text.deposit_sber_x'
                      : isTinkoffX
                        ? 'form.text.deposit_tinkoff_x'
                        : 'form.text.deposit'
                    : isPhoneNumberField
                      ? 'form.text.withdrawal_spb'
                      : 'form.text.withdrawal'
                }
              />
            </h2>
            {!!method && CARD_PAYMENT_METHODS.includes(method.name) ? (
              method.name === PaymentMethodName.SberbankXCountry ||
              method.name === PaymentMethodName.TinkoffXCountry ? (
                <CreditCard
                  amount={amount}
                  cardNumber={credential}
                  currency={data.currencyInfo}
                  method={method}
                  owner={credentials.owner}
                  countryCode={credentials.country_code}
                  bankName={credentials.bank_name}
                  mode={isDeposit ? 'read-only' : 'input'}
                  type={credentials.type}
                  onChange={setCredential}
                />
              ) : (
                <CreditCard
                  amount={amount}
                  cardNumber={credential}
                  currency={data.currencyInfo}
                  method={method}
                  mode={isDeposit ? 'read-only' : 'input'}
                  onChange={setCredential}
                />
              )
            ) : (
              <>
                <div className="flex items-center justify-between">
                  <div className="flex-1 flex items-center gap-3.5">
                    <PaymentMethodLogo
                      className={classNames(getPaymentMethodDesign(method), {
                        'p-1 object-contain rounded-xl': isPhoneNumberField,
                      })}
                      method={method}
                      height={40}
                      width={40}
                    />
                    <div className="flex-1 flex flex-col gap-4">
                      <div>
                        <span className="block uppercase text-brand-2 text-xs">
                        <Text id={isPhoneNumberField ? 'form.field.phoneNumber' : 'form.field.credential'} />
                      </span>
                        {isDeposit ? (
                          <strong className="font-semibold text-brand-6">
                            {formatCredential(credential, method)}
                          </strong>
                        ) : (
                          <>
                            <InputMask
                              mask="+7 (999) 999-99-99"
                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                setIsPhoneError(false);
                                // @ts-expect-error
                                const result = !!event.target.value && event.target.value.match(/\d/gi).splice(1).join('');

                                if (result && result.length === 10) {
                                  if (!result.startsWith('9')) {
                                    setIsPhoneError(true);
                                    return;
                                  }
                                  setCredential(7 + result);
                                }
                              }}
                              type="tel"
                            >
                              {() => (
                                <input
                                  ref={ref}
                                  placeholder="+7 (999) 999-99-99"
                                  className="mt-1 input rounded-md p-1 w-full"
                                />
                              )}
                            </InputMask>
                            {isPhoneError && <span className="block mt-2 text-xs text-rose-600 "><Text
                              id="form.field.phoneNumberError" /></span>}
                          </>
                        )}
                      </div>
                      <div>
                        <span className="block uppercase text-brand-2 text-xs">
                          <Text id="form.field.banks" />
                        </span>
                        {isBankSelectorVisible && <PaymentFormBankSelector formId={formId} onBankSelect={onBankSelect} />}
                      </div>
                    </div>
                  </div>
                  {isDeposit && <CopyButton value={credential} />}
                </div>
                <hr className="my-7 border-gray-1" />
                <div className="mb-7 flex items-center justify-between">
                  <div className="flex gap-3.5">
                    <img alt="Wallet" src={walletIcon} height={40} width={40} />
                    <div>
                      <span className="block uppercase text-brand-2 text-xs">
                        <Text id="form.field.amount" />
                      </span>
                      <strong className="font-semibold text-brand-6">
                        <Money amount={amount} currency={data.currencyInfo} />
                      </strong>
                    </div>
                  </div>
                  {isDeposit && (
                    <CopyButton
                      value={String(normalizeAmount(amount, data.currencyInfo))}
                    />
                  )}
                </div>
              </>
            )}
            <hr className="my-7 border-gray-1" />
            <div className="opacity-70 text-center">
              <span className="mb-1 block uppercase text-brand-2 text-xs text-center">
                <Text id="form.field.payment_id" />
              </span>
              <strong className="mb-1 block font-semibold text-brand-6">
                {formId}
              </strong>
              <CopyButton value={formId} />
              <span className="mt-4 block text-brand-2 text-xs text-center">
                <Text id="form.text.payment_id" />
              </span>
            </div>
          </div>
        </main>
        <footer className="flex">
          <button
            className="flex-1 py-[19px] rounded-l-3xl text-base font-semibold bg-brand-1 text-white"
            onClick={depositSuccess}
            type="button"
          >
            <Text
              id={
                isDeposit ? 'form.button.have_paid' : 'form.button.withdrawal'
              }
            />
          </button>
          <Timer deadline={new Date(data.expiredIn)} onFinish={onError} />
        </footer>
      </Fragment>
    </PaymentPageLayout>
  );
};
