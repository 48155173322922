import { Tr } from './table/tr';
import cx from 'classnames';
import { Tab } from '@headlessui/react';
import { TerminalStatsApi } from '../../../api';
import { useQuery } from '@tanstack/react-query';
import type { Terminal } from '../../../model';
import { Spinner } from '../../../components';

export const FETCH_INTERVAL = Number(process.env.REACT_APP_REFETCH_INTERVAL_IN_SECONDS) ?? 20;

export const TabPanel = (props: {
  merchant: { merchantId: number; name: string; terminals: Terminal[] }
  selectedMinutes: { value: number; title: string };
  isActive: boolean
}) => {
  const { data: terminalData } = useQuery({
    queryKey: [TerminalStatsApi.KEY, props.selectedMinutes.value, props.merchant.merchantId],
    queryFn: () => TerminalStatsApi.getTerminal(props.merchant.merchantId, props.selectedMinutes.value),
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * FETCH_INTERVAL,
    enabled: props.isActive
  });

  return (
    <Tab.Panel>
      {terminalData
        ? <div className="overflow-x-auto">
          <table
            className="min-w-full text-start text-sm font-light text-surface w-5/5">
            <thead className="border-b border-neutral-200 font-medium">
            <tr className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr]">
              <th scope="col" className="pb-5 uppercase text-start">name</th>
              <th scope="col" className="pb-5 uppercase text-center">enabled</th>
              <th scope="col" className="pb-5 uppercase text-center">NEW</th>
              <th scope="col" className="pb-5 uppercase text-center">success</th>
              <th scope="col" className="pb-5 uppercase text-center">canceled</th>
              <th scope="col" className="pb-5 uppercase text-center">declined</th>
            </tr>
            </thead>
            <tbody>
            {props.merchant.terminals.map(terminal => {
              const currentTerminalData = terminalData.find(item => item.terminalId === terminal.terminalId);

              if (!currentTerminalData) {
                return (
                  <tr
                    key={terminal.terminalId}
                    className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] border-b">
                    <td className="whitespace-nowrap py-2">{terminal.name}</td>
                    <td className={cx('whitespace-nowrap text-white text-center py-2', {
                      'bg-green-800': terminal.enable,
                      'bg-red-800': !terminal.enable,
                    })}>{terminal.enable ? 'on' : 'off'}</td>
                    <td className="whitespace-nowrap py-2 text-center">no data</td>
                    <td className="whitespace-nowrap py-2 text-center">no data</td>
                    <td className="whitespace-nowrap py-2 text-center">no data</td>
                    <td className="whitespace-nowrap py-2 text-center">no data</td>
                  </tr>
                );
              }

              return (
                <Tr
                  key={terminal.terminalId}
                  {...terminal}
                  {...currentTerminalData}
                  enable={terminal.enable}
                />
              );
            })}
            </tbody>
          </table>
        </div>
        : <Spinner />
      }
    </Tab.Panel>
  );
};