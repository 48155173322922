import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, CopyButton, Spinner } from '../../../components';
import type { Merchant } from '../../../model';
import { splitIntoChunks } from '../lib';
import { MerchantApi } from '../../../api';

export const MerchantForm = ({ merchant }: { merchant: Merchant }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Merchant>({
    mode: 'onSubmit',
  });

  const queryClient = useQueryClient();
  const onSaveMutation = useMutation({
    mutationFn: MerchantApi.saveMerchant,
    onSuccess: () => {
      queryClient.refetchQueries([MerchantApi.KEY, 'me']);
    },
  });

  // @ts-ignore
  const onSubmit = ({ name }) => {
    onSaveMutation.mutate({ name, id: merchant.id });
  };

  return (
    <form className="relative" onSubmit={handleSubmit(onSubmit)}>
      {merchant
        ? (
          <div key={merchant.id} className="text-xs text-brand grid">
            <div className="flex gap-4 mb-5">
              <span className="w-40 text-brand-2">ID</span>
              <span>{merchant.id}</span>
            </div>
            <div className="flex gap-4 mb-5">
              <span className="w-40 text-brand-2">Name</span>
              <input
                defaultValue={merchant.name}
                {...register('name', {
                  required: 'Name is required',
                  minLength: {
                    value: 3,
                    message: 'Name must be at least 3 characters long',
                  },
                })}
                className="p-1 border-brand-1 border-2 rounded outline-none"
              />
            </div>
            <div className="flex gap-4 mb-5">
              <span className="w-40 text-brand-2">Public key</span>
              <span className="grid grid-rows-[auto_auto_auto]">
                {
                  splitIntoChunks(merchant.clientPublicKey, Math.round(merchant.clientPublicKey.length / 3))
                    .map(string => <p key={string} className="mt-1">{string}</p>)
                }
              </span>
              <CopyButton className="h-fit" value={merchant.clientPublicKey} />
            </div>
            {errors && errors && errors['name'] && <span>{errors['name'].message}</span>}
          </div>
        )
        : <Spinner />
      }
      <div className="mt-2 flex gap-2">
        <Button>
          {onSaveMutation.isLoading ? <Spinner className="text-white" /> : 'Save'}
        </Button>
      </div>
    </form>
  );
};