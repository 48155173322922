import classNames from 'classnames';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import type { FC } from 'react';

interface Props {
  className?: string;
}

export const Logo: FC<Props> = ({ className }) => {
  return (
    <span className={classNames('flex items-center font-rubik', className)}>
      <LogoIcon />
      <span className="text-black font-bold">Dzen</span>
      <span className="text-brand-5 font-normal">Pay</span>
    </span>
  );
};
