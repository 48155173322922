import { Text } from '@eo-locale/react';
import { type FC } from 'react';
import { CopyButton } from './CopyButton';
import { PaymentPageLayout } from '../../layouts';

interface Props {
  formId: string;
  redirectUrl: string;
}

export const DepositFail: FC<Props> = ({ formId, redirectUrl }) => (
  <PaymentPageLayout>
    <main className="flex-1 flex-center lg:block">
      <div className="w-full pt-[200px] text-center bg-deposit-fail">
        <h1 className="mb-2.5 text-4xl font-bold text-brand-6">
          <Text id="form.header.fail" />
        </h1>
        <span className="block text-brand-2 text-sm">
          <Text id="form.text.fail" />
        </span>
        <div className="mt-12 opacity-70 text-center">
          <span className="mb-1 block uppercase text-brand-2 text-xs text-center">Invoice ID</span>
          <strong className="mb-1 block font-semibold text-brand-6">{formId}</strong>
          <CopyButton value={formId} />
        </div>
      </div>
    </main>
    <footer>
      {redirectUrl ?
        <a className="button button--block button--primary" href={`${redirectUrl.startsWith('http') ? '' : '//'}${redirectUrl}`}>
          <Text id="form.button.continue" />
        </a>
          : ''}
    </footer>
  </PaymentPageLayout>
);
