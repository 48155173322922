import type { Terminal } from '../../../../model';
import cx from 'classnames';

export const Tr = (props: Terminal) => {
  const { successPercentLowThreshold, successPercentHighThreshold } = props;

  return (
    <tr className="grid grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr] border-b">
      <td className="whitespace-nowrap py-2">{props.name}</td>
      <td className={cx('whitespace-nowrap text-white text-center py-2', {
        'bg-green-800': props.enable,
        'bg-red-800': !props.enable,
      })}>{props.enable ? 'on' : 'off'}</td>
      <td className="whitespace-nowrap text-center py-2">{props.newCount}</td>
      <td className="whitespace-nowrap text-center py-2">{props.successCount} | <span className={cx({
        'text-[#dc2626]': props.successPercent < successPercentLowThreshold,
        'text-orange-600': props.successPercent >= successPercentLowThreshold && props.successPercent < successPercentHighThreshold,
        'text-green-600': props.successPercent >= successPercentHighThreshold,
      })}>{props.successPercent}%</span></td>
      <td className="whitespace-nowrap text-center py-2">{props.cancelCount} | {props.cancelPercent}%</td>
      <td className="whitespace-nowrap text-center py-2">{props.declinedCount} | {props.declinedPercent}%</td>
    </tr>
  );
};