import type { FC } from 'react';
import { Avatar, Tag } from '../components';
import { NotificationStatus, TagType } from '../model';

const notifications = [
  {
    name: 'Amarliya G.',
    paymentStatus: "The money still hasn't arrived",
    notificationStatus: NotificationStatus.InProcess,
    type: TagType.Warning,
  },
  {
    name: 'Prateek G.',
    paymentStatus: 'Thanks a lot',
    notificationStatus: NotificationStatus.Closed,
    type: TagType.Error,
  },
  {
    name: 'Radha B.',
    paymentStatus: "The money still hasn't arrived",
    notificationStatus: NotificationStatus.InProcess,
    type: TagType.Warning,
  },
];

export const NotificationsPage: FC = () => {
  return (
    <>
      <h1 className="text-4xl text-brand-6 font-bold">Notifications</h1>
      <ul className="grid gap-6 bg-white rounded-3xl p-8 mt-4 w-3/5">
        {notifications.map((notification, index) => (
          <li className="flex justify-between items-center" key={index}>
            <div className="flex items-center gap-3">
              <Avatar
                name={notification.name}
                inProgress={notification.notificationStatus === NotificationStatus.InProcess}
              />
              <div className="flex flex-col justify-between gap-1">
                <div className="uppercase font-bold text-sm">{notification.name} </div>
                <div className="capitalize text-xs text-brand-2">{notification.paymentStatus} </div>
              </div>
            </div>
            <Tag type={notification.type}>{notification.notificationStatus}</Tag>
          </li>
        ))}
      </ul>
    </>
  );
};
