import { useMutation, useQuery } from '@tanstack/react-query';
import { type FC, useCallback, useState } from 'react';
import { LuSlidersHorizontal, LuUpload } from 'react-icons/lu';
import { useToggle } from 'usehooks-ts';
import { GetInvoicesFilter, InvoiceApi } from '../api';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { BalanceWidget, InvoiceRow, InvoicesFilterForm, Modal, Pagination } from '../components';
import { useTerminalStore } from '../store';
import { useProfile } from '../hooks';

const ITEMS_PER_PAGE_OPTIONS = [5, 10, 15, 25, 40];

export const InvoicesPage: FC = () => {
  const [filter, setFilter] = useState<GetInvoicesFilter>();
  const [isOpen, toggle] = useToggle();
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const profile = useProfile();

  const { terminalId } = useTerminalStore();

  const { mutate: getReport } = useMutation({
    mutationFn: InvoiceApi.getReport,
  });

  const { data: invoices, refetch } = useQuery({
    queryKey: [
      InvoiceApi.KEY,
      'getByMerchant',
      currentPage,
      searchText,
      JSON.stringify(filter),
      itemsPerPage,
      terminalId,
    ],
    queryFn: () =>
      InvoiceApi.getByMerchant({
        ...filter,
        terminalId,
        page: currentPage - 1,
        search: searchText,
        size: itemsPerPage,
        statusIds: (filter?.statusIds ?? []).filter(Boolean),
      }),
    keepPreviousData: true,
  });

  const isBalanceWidgetEnabled =  !!profile?.permissions.includes('merchant.read.finance')

  const handleChangeSearchText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const handleChangeFilter = useCallback(
    (newFilter: GetInvoicesFilter) => {
      setFilter(newFilter);
      toggle();
      refetch();
    },
    [refetch, setFilter, toggle],
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={toggle}>
        <InvoicesFilterForm onChange={handleChangeFilter} value={filter} />
      </Modal>
      {isBalanceWidgetEnabled && <BalanceWidget />}
      <header className="mt-4 flex justify-between">
        <h1 className="text-4xl text-brand-6 font-bold">Transactions</h1>
        <button
          className="button button--primary px-4 py-2.5 rounded-xl"
          onClick={() =>
            // @ts-ignore
            getReport({
              ...filter,
              terminalId,
              search: searchText,
              statusIds: (filter?.statusIds ?? []).filter(Boolean),
            })
          }
          type="button"
          title="Get report"
        >
          <LuUpload />
        </button>
      </header>
      <div className="flex gap-4 w-full mt-4">
        <button
          className="button button--primary px-4 py-2.5 rounded-xl"
          onClick={toggle}
          type="button"
        >
          <LuSlidersHorizontal />
        </button>
        <div className="flex-1 relative">
          <input
            className="w-full px-4 py-2.5 bg-white rounded-xl"
            onChange={handleChangeSearchText}
            placeholder="Search"
            type="text"
            value={searchText}
          />
          <SearchIcon className="absolute right-2 top-1/2 transform translate-y-[-50%]" />
        </div>
      </div>

      <ul className="bg-white rounded-3xl p-3 mt-4">
        {invoices?.data.map((invoice) => (
          <InvoiceRow key={invoice.invoiceId} invoice={invoice} />
        ))}
        {invoices?.data.length === 0 && (
          <li className="flex items-center p-4 cursor-pointer">
            Nothing found
          </li>
        )}
      </ul>
      {invoices && (
        <footer className="mt-4 flex gap-12 px-6">
          <Pagination
            totalItems={invoices.total}
            onPageChange={setCurrentPage}
            itemsPerPage={itemsPerPage}
          />
          <select
            className="input px-2 py-1 rounded text-xs appearance-none bg-transparent border-brand-1"
            onChange={(event) => {
              setItemsPerPage(Number(event?.target.value));
            }}
            value={itemsPerPage}
          >
            {ITEMS_PER_PAGE_OPTIONS.map((option) => (
              <option key={option} value={option}>
                Items Per Page {option}
              </option>
            ))}
          </select>
        </footer>
      )}
    </>
  );
};
