import { useQuery } from '@tanstack/react-query';
import { PeopleApi } from '../api';
import { useAuthContext } from './useAuthContext';
import type { Profile } from '../model';

export function useProfile(): Profile | undefined {
  const { signOut } = useAuthContext();

  const { data } = useQuery({
    queryKey: [PeopleApi.KEY, 'me'],
    queryFn: PeopleApi.me,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    onError() {
      signOut();
    },
  });

  return data;
}
