import type { TerminalsByMerchant } from '../../../../model';
import { Tab } from '@headlessui/react';
import cx from 'classnames';
import { TabForm } from './TabForm';
import { Fragment } from 'react';

export const TerminalTabs = ({ data }: {
  data: TerminalsByMerchant,
}) => {
  return (
    <div className="w-full grid grid-rows-[50px_1fr] h-full gap-2">
      <Tab.Group>
        <Tab.List className="flex space-x-1 bg-brand-1/15 rounded-xl p-1 overflow-x-auto max-w-7xl h-fit">
          {data.map(terminal => {
            return (
              <Tab as={Fragment} key={terminal.id}>
                  {({ selected }) => (
                      <button
                          className={cx('rounded-lg p-2.5 text-sm font-medium leading-5 text-white ',
                              'ring-white/60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-2 text-white shadow',
                              selected
                                  ? 'bg-brand-1'
                                  : 'bg-brand-1/50',
                          )}
                      >
                          <span className="whitespace-nowrap">{terminal.name}</span>
                      </button>
                  )}
              </Tab>
            );
          })}
        </Tab.List>
        <Tab.Panels as={Fragment}>
          {data.map((terminal) => (
            <Tab.Panel
              key={terminal.id}
              className="outline-0 h-full overflow-visible"
            >
              <TabForm key={terminal.id} id={terminal.id} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
