import { useQuery } from '@tanstack/react-query';
import type { FC } from 'react';
import { MerchantApi } from '../../api';
import { useTerminalStore } from '../../store';
import { BalanceWidgetCard } from '../molecules';

const TITLES = [
  'balance',
  'deposit',
  'withdrawal',
] as const;

export const BalanceWidget: FC = () => {
  const { terminalId } = useTerminalStore();

  const { data: balance } = useQuery({
    enabled: !!terminalId,
    queryKey: [MerchantApi.KEY, 'getBalance', terminalId],
    queryFn: () => MerchantApi.getBalance(terminalId),
  });
  const { data: deposit } = useQuery({
    enabled: !!terminalId,
    queryKey: [MerchantApi.KEY, 'getDeposit', terminalId],
    queryFn: () => MerchantApi.getDeposit(terminalId),
  });
  const { data: withdrawal } = useQuery({
    enabled: !!terminalId,
    queryKey: [MerchantApi.KEY, 'getWithdrawal', terminalId],
    queryFn: () => MerchantApi.getWithdrawal(terminalId),
  });

  if(!terminalId) {
    return <></>
  }

  return (
    <ul className="flex w-full gap-4">
      {[balance, deposit, withdrawal].map((item, index) => (
        <BalanceWidgetCard
          key={TITLES[index]}
          item={item}
          title={TITLES[index]}
        />
      ))}
    </ul>
  );
};
