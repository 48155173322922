import { FC, HTMLAttributes, useCallback } from 'react';
import { TagType } from '../../model';
import { Tag } from '../atoms';
import { useCopyToClipboard } from 'usehooks-ts';
import cx from 'classnames';

interface Props {
  value: string;
}

export const CopyButton: FC<Props & HTMLAttributes<HTMLButtonElement>> = ({ value, ...props }) => {
  const [copiedValue, copy] = useCopyToClipboard();

  const handleClick = useCallback(() => {
    copy(value);
  }, [copy, value]);

  if (copiedValue !== null) {
    return (
      <Tag className={cx('px-2 py-1 cursor-pointer', props.className)} type={TagType.Success}>
        Copied
      </Tag>
    );
  }

  return (
    <Tag className={cx('px-2 py-1 cursor-pointer', props.className)} onClick={handleClick} type={TagType.Primary}>
      Copy
    </Tag>
  );
};
