import { useMutation } from '@tanstack/react-query';
import { type FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ReportApi, ReportParams } from '../../api';
import { useMerchant, useProfile } from '../../hooks';
import { formatDateForInput } from '../../utils';
import { useTerminalStore } from '../../store';

export const TransactionsReportCard: FC = () => {
  const merchant = useMerchant();
  const profile = useProfile();

  const { terminalId } = useTerminalStore();
  const { handleSubmit, register, watch } = useForm<ReportParams>();

  const periodStart = watch('periodStart');
  const periodEnd = watch('periodEnd');

  const { mutate } = useMutation({
    mutationFn: ReportApi.invoice,
  });

  const onSubmit = useCallback(
    (data: ReportParams) => {
      if (merchant) {
        mutate({
          ...data,
          terminalId,
        });
      }
    },
    [merchant, terminalId, mutate],
  );

  if (!profile || !profile.permissions.includes('report.read.invoice')) {
    return null;
  }

  return (
    <li className="p-4 rounded-2xl shadow-1 bg-white">
      <h2 className="mb-5 text-lg font-bold text-center">Transactions</h2>
      <form className="grid gap-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <label>
            <span className="mb-1 block text-brand-2 text-sm pl-4">Date From</span>
            <input
              {...register('periodStart', {
                required: true,
                valueAsDate: true,
              })}
              className="input w-full"
              max={formatDateForInput(new Date(periodEnd))}
              type="date"
            />
          </label>
          <label>
            <span className="mb-1 block text-brand-2 text-sm pl-4">Date To</span>
            <input
              {...register('periodEnd', {
                required: true,
                valueAsDate: true,
              })}
              className="input w-full"
              min={formatDateForInput(new Date(periodStart))}
              type="date"
            />
          </label>
        </div>
        <button className="button button--block button--primary" type="submit">
          Get report
        </button>
      </form>
    </li>
  );
};
