import { Button, Select, Spinner } from "../../../../components";
import { CODE_VALUES } from "../../codeValues";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TerminalApi } from "../../../../api";
import type { AttrType } from "../../../../model";
import { toast } from "react-toastify";

export const TableRow = ({
  id,
  value,
  code,
  terminalId,
  items,
}: {
  code: keyof typeof CODE_VALUES;
  id: string;
  terminalId: string;
  value: string;
  items: AttrType[];
}) => {
  const queryClient = useQueryClient();

  const onAttrRemoveMutation = useMutation({
    mutationFn: TerminalApi.removeAttribute,
    onSuccess: () => {
      queryClient.refetchQueries([TerminalApi.KEY, terminalId]);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const onAttrRemove = (terminalId: string, attributeId: string) => {
    onAttrRemoveMutation.mutate({ terminalId, attributeId });
  };

  return (
    <tr>
      <td className="border-b border-slate-700 text-center p-2">
        <Select items={items} disabled />
      </td>
      <td className="border-b border-slate-700 text-center p-2">
        <span className="block truncate max-w-[200px] m-auto">{value}</span>
      </td>
      <td className="border-b border-slate-700 text-center p-2">
        <span className="block truncate max-w-[200px] m-auto">
          {CODE_VALUES[code]}
        </span>
      </td>
      <td className="border-b border-slate-700 text-center p-2">
        <Button
          className="w-full"
          onClick={(event) => {
            event.preventDefault();
            onAttrRemove(terminalId, id as unknown as string);
          }}
        >
          {onAttrRemoveMutation.isLoading ? (
            <Spinner className="text-white" />
          ) : (
            "Remove"
          )}
        </Button>
      </td>
    </tr>
  );
};
