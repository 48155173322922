import axios from "axios";
import type { Profile } from "../model";

export class PeopleApi {
  static readonly KEY = "people";

  static async me(): Promise<Profile> {
    const { data } = await axios.get("/api/v1/people/me");

    return data.data;
  }

  static async switchTerminalMode() {
    return await axios.patch(`/api/v1/people/me/testDashboard`);
  }
}
