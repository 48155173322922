import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type FC, Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { InvoiceApi, ReferenceApi } from '../../api';
import { Reference } from '../../config';
import { Modal } from './Modal';
import { EnumEntity } from '../../model';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/16/solid';
import { Listbox, Transition } from '@headlessui/react';
import { Button } from '../atoms';

interface Props {
  invoiceId: string;
  statusId: number;
  status: EnumEntity;
}

export const InvoiceStatusSelector: FC<Props> = ({ invoiceId, statusId, status }) => {
  const [newStatusId, setNewStatusId] = useState<number>(statusId);
  const [statusList, setStatusList] = useState<EnumEntity[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['invoiceStatus', invoiceId],
    queryFn: () => ReferenceApi.getById(Reference.InvoiceStatus),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    data && setStatusList(data);
  }, [data]);

  const { mutate, isLoading } = useMutation({
    mutationFn: InvoiceApi.changeStatus,
    onSuccess() {
      queryClient.refetchQueries(['invoice', 'getByMerchant', 'getEvents', invoiceId]).then(() => {
        toast.success('Status changed successfully!');
        setIsOpen(false);
      });
    },
    onError() {
      toast.error('Failed to change status');
    },
  });

  const onChange = (event: { id: number }) => {
    if (Number(event.id) === statusId) return;
    setNewStatusId(Number(event.id));
    setIsOpen(true);
  };

  const onConfirm = () => {
    mutate({
      invoiceId,
      statusId: newStatusId!,
    });
  };

  const onCancel = () => {
    setIsOpen(false);
    setNewStatusId(statusId);
  };

  const oldStatusName = statusList.find((status) => status.id === statusId)?.value;
  const newStatusName = statusList.length ? statusList.find((status) => status.id === newStatusId)?.value : status.value;

  return (
    <>
      <div className="w-36">
        <Listbox value={status} onChange={onChange}>
          <div className="relative mt-1">
            <Listbox.Button
              className="relative w-full cursor-default rounded-lg bg-white py-1 px-2 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">{newStatusName}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {statusList.map((status, statusIdx) => status.id !== statusId && (
                  <Listbox.Option
                    key={statusIdx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-1 px-2 ${
                        active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                      }`
                    }
                    value={status}
                  >
                    {({ selected }) => (
                      <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {status.value}
                      </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
      <Modal isOpen={isOpen} onClose={onCancel}>
        <div className="p-6">
          <h2 className="text-lg text-center">
            Are you sure you want to change the payment status from <strong>{oldStatusName}</strong> to{' '}
            <strong>{newStatusName}</strong>?
          </h2>
          <div className="mt-8 flex justify-center gap-4">
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onConfirm} disabled={isLoading}>Continue</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};