import cx from "classnames";
import type { HTMLAttributes } from "react";
import { Fragment } from "react";
import { Switch as HeadlessUiSwitch } from "@headlessui/react";

type SwitchProps = { checked: boolean } & HTMLAttributes<HTMLButtonElement>;

export const Switch = ({ checked, onClick }: SwitchProps) => {
  return (
    <Fragment>
      <HeadlessUiSwitch checked={checked} as={Fragment}>
        {({ checked }) => (
          <button
            onClick={onClick}
            disabled={checked}
            className={cx(
              "group inline-flex h-6 w-11 items-center rounded-full bg-brand-1",
              checked && "cursor-not-allowed opacity-50",
            )}
          >
            <span
              className={cx(
                "size-4 rounded-full transition",
                checked
                  ? "bg-rose-500 translate-x-6"
                  : "bg-green-500 translate-x-1",
              )}
            />
          </button>
        )}
      </HeadlessUiSwitch>
      <span
        className={cx("text-green-500", {
          "text-rose-500": checked,
        })}
      >
        {checked ? "In prod" : "In test"}
      </span>
    </Fragment>
  );
};
