import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface MerchantStore {
  merchantId?: number;
  changeMerchantId(merchantId: number): void;
}

export const useMerchantStore = create(
  persist<MerchantStore>(
    (set) => ({
      changeMerchantId: (merchantId: number) => set(() => ({ merchantId })),
    }),
    {
      name: 'merchant-store',
    },
  ),
);
