import type { FC } from 'react';
import { TagType, type EventLog } from '../../model';
import { Tag } from '../atoms';
import {formatDateUTC} from "../../utils/lib";

interface Props {
  eventLog: EventLog;
}
export const EventLogRow: FC<Props> = ({ eventLog }) => {
  return (
    <li
      className="flex items-center p-4 cursor-pointer hover:bg-brand-12 transition-colors rounded-2xl"
      key={eventLog.id}
    >
      <div className="flex-1">
        <div className="text-center">
          <Tag type={TagType.Neutral}>
            {eventLog.eventCode}
          </Tag>
        </div>
      </div>
      <div className="flex-1">
        <div className="text-center">
            <span className="block text-xs text-brand-2">
              {eventLog.description}
            </span>
        </div>
      </div>
      <div className="flex-1">
        <div className="text-center">
            <span className="block text-xs text-brand-2">
              {formatDateUTC(eventLog.eventTime, 'long')}
            </span>
        </div>
      </div>
      <div className="flex-1">
        <div className="text-center">
            <span className="block text-xs text-brand-2">
              {eventLog.userId}
            </span>
        </div>
      </div>
    </li>
  );
};
