import { useQuery } from '@tanstack/react-query';
import { TerminalStatsApi } from '../../api';
import { Spinner } from '../../components';
import { Fragment, useCallback, useState } from 'react';
import { Listbox, Tab, Transition } from '@headlessui/react';
import cx from 'classnames';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/16/solid';
import { TabPanel } from './ui/TabPanel';

const minutes = [
  { value: 0, title: 'From day start' },
  { value: 60, title: '1 h' },
  { value: 180, title: '3 h' },
];


export const MonitoringPage = () => {
  const [selectedMinutes, setSelectedMinutes] = useState(minutes[1]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { data: terminalListByMerchant } = useQuery({
    queryKey: [],
    queryFn: TerminalStatsApi.getTerminalsStats,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const onChange = useCallback((item: typeof selectedMinutes) => {
    if (!item.value) {
      const date = new Date();
      setSelectedMinutes({ ...item, value: date.getUTCHours() * 60 + date.getUTCMinutes() });
      return;
    }
    setSelectedMinutes(item);
  }, []);

  const onTabsChange = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <Fragment>
      <h1 className="mb-4 text-4xl text-brand-6 font-bold">Monitoring</h1>
      <div className="p-7 w-5/5 bg-white rounded-3xl relative grow">
        {terminalListByMerchant
          ? (
            <Fragment>
              <div className="flex justify-between items-center gap-5 absolute z-10 right-7">
              <span className="flex items-center gap-2 text-lg font-bold">
                Period:
              </span>
                <Listbox onChange={onChange} defaultValue={selectedMinutes}>
                  <div style={{ minWidth: 175 }}>
                    <Listbox.Button
                      className="relativecursor-default min-w-full rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className="block truncate">{selectedMinutes.title}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        className="absolute mt-1 max-h-60 w-full max-w-max right-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {minutes.map((minute) => (
                          <Listbox.Option
                            key={minute.value}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active ? 'bg-brand-1/20 text-brand-1' : 'text-gray-900'
                              }`
                            }
                            value={minute}
                          >
                            {({ selected }) => (
                              <Fragment>
                          <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                           {minute.title}
                          </span>
                                {selected && (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brand-1">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                                )}
                              </Fragment>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
              <div className="overflow-x-auto grid gap-5 h-full grid-rows-[auto_1fr] relative">
                <Tab.Group onChange={onTabsChange} selectedIndex={selectedIndex}>
                  <Tab.List className="flex space-x-1 bg-brand-1/15 rounded-xl p-1 overflow-x-auto max-w-5xl h-fit">
                    {terminalListByMerchant.map((merchant, index) => (
                      <Tab as={Fragment} key={merchant.merchantId}>
                        {({ selected }) => (
                          <button
                            className={cx('rounded-lg p-2.5 text-sm font-medium leading-5 text-white ',
                              'ring-white/60 ring-offset-2 ring-offset-white focus:outline-none focus:ring-2 text-white shadow',
                              selected
                                ? 'bg-brand-1'
                                : 'bg-brand-1/50',
                            )}
                          >
                            {merchant.name}
                          </button>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="h-full grid grid-rows-[auto_1fr]">
                    {terminalListByMerchant.map((merchant, index) => (
                      <TabPanel key={merchant.merchantId} merchant={merchant} selectedMinutes={selectedMinutes}
                                isActive={selectedIndex === index} />
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </Fragment>
          )
          : <Spinner />}
      </div>
    </Fragment>
  );
};
