import type { Balance } from '../../model';
import { Money } from '../atoms';

type BalanceWidgetCardProps = {
  item: Balance | undefined
  title: 'balance' | 'deposit' | 'withdrawal'
}

export const BalanceWidgetCard = ({ item, title }: BalanceWidgetCardProps) => {
  return (
    <li className="flex-1 p-4 rounded-2xl shadow-1 bg-white text-center grid grid-rows-[auto_auto]">
      <span className="block text-brand-2 first-letter:uppercase">{title}</span>
      <span className="relative h-[30px] flex-center">
          {!!item?.balance
            ? <strong>
              <Money amount={item?.balance[0].sum} currency={item?.balance[0].currency} />
            </strong>
            : (
              <div className="animate-pulse">
                <div className="h-[20px] rounded bg-brand-2 w-fit text-brand-2">{title}</div>
              </div>
            )
          }
        </span>
    </li>
  );
};
