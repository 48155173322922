import { Text, useTranslator } from '@eo-locale/react';
import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';
import { CredentialParams } from '../../api';
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { PaymentPageLayout } from '../../layouts';
import { PaymentFormType, type PaymentFormData, type PaymentMethod } from '../../model';
import { formatMoney, getPaymentMethodDesign, normalizeAmount } from '../../utils';
import { Money, PaymentMethodLogo, Timer } from '../atoms';

const PRESETS = [100_000, 1_000_000, 5_000_000, 10_000_000];
const STEP = 100_000;

interface Props {
  data: PaymentFormData;
  formId: string;
  method?: PaymentMethod;

  onBack(): void;
  onError(): void;
  onRequestCredential(params: CredentialParams): void;
}

export const PaymentFormStepInit: FC<Props> = ({
  data,
  formId,
  method,

  onBack,
  onError,
  onRequestCredential,
}) => {
  const translator = useTranslator();
  const [amount, setAmount] = useState(data.amount);

  const add = useCallback(() => {
    const newValue = amount + STEP;
    if (newValue <= data.currencyInfo.maxValue) {
      setAmount(newValue);
    }
  }, [amount, data.currencyInfo]);

  const subtract = useCallback(() => {
    const newValue = amount - STEP;
    if (newValue >= data.currencyInfo.minValue) {
      setAmount(newValue);
    }
  }, [amount, data.currencyInfo]);

  const requestCredential = useCallback(() => {
    onRequestCredential({
      amount,
      formId,
      paymentMethodId: method?.id,
    });
  }, [amount, formId, method, onRequestCredential]);

  return (
    <PaymentPageLayout>
      <header className="mb-7">
        <h1 className="text-brand-6 text-lg text-center font-bold">
          <Text id="form.header.payment" />
        </h1>
      </header>
      <main className="flex-1">
        {method && (
          <div
            className={classNames('mb-5 py-2 flex-center gap-4 rounded cursor-pointer', getPaymentMethodDesign(method))}
            onClick={onBack}
          >
            <PaymentMethodLogo className="h-8" method={method} />
            {method.name}
          </div>
        )}
        <div className="mb-2.5 flex justify-between text-brand-2 text-xs font-semibold">
          <span>
            <Text id="form.field.limits" />
          </span>
          <span>
            {/*// @ts-expect-error*/}
            <Money amount={method.minAmount} currency={data.currencyInfo} />
            &nbsp;-&nbsp;
            {/*// @ts-expect-error*/}
            <Money amount={method.maxAmount} currency={data.currencyInfo} />
          </span>
        </div>
        <div className="mb-3.5 flex justify-between items-center px-5 py-3.5 border border-gray-1 rounded-3xl">
          <button className="flex-center h-8 w-8 shadow-1 rounded-full" onClick={subtract} type="button">
            <MinusIcon />
          </button>
          <span className="font-bold text-brand-6 text-xl">
            <Money amount={amount} currency={data.currencyInfo} />
          </span>
          <button className="flex-center h-8 w-8 shadow-1 rounded-full" onClick={add} type="button">
            <PlusIcon />
          </button>
        </div>
        <div className="flex flex-wrap justify-center gap-2">
          {PRESETS.map((preset) => (
            <button
              className="px-3.5 py-1.5 border-2 border-brand-1 text-xs text-brand-1 font-bold rounded-xl"
              onClick={() => setAmount(preset)}
              key={preset}
              type="button"
            >
              <Money
                amount={normalizeAmount(preset, data.currencyInfo)}
                currency={{
                  ...data.currencyInfo,
                  dimension: 0,
                }}
              />
            </button>
          ))}
        </div>
      </main>
      <footer className="flex">
        <button
          className="flex-1 py-[19px] rounded-l-3xl text-base font-semibold bg-brand-1 text-white"
          onClick={requestCredential}
          type="button"
        >
          <Text
            id={data.type === PaymentFormType.Deposit ? 'form.button.deposit' : 'form.button.withdrawal'}
            amount={formatMoney(amount, data.currencyInfo, translator.language)}
          />
        </button>
        <Timer deadline={new Date(data.expiredIn)} onFinish={onError} />
      </footer>
    </PaymentPageLayout>
  );
};
