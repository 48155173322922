import { useQuery } from '@tanstack/react-query';
import { Fragment, useRef, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Spinner } from '../atoms';
import { Text } from '@eo-locale/react';

type Item = {
  id: string,
  value: string,
  code: string,
  codeInteger: number
}

type PaymentFormBankSelectorProps = {
  formId: string
  onBankSelect: (bank: number) => void
}

export const PaymentFormBankSelector = ({ formId, onBankSelect }: PaymentFormBankSelectorProps) => {
  const { data } = useQuery<{ data: Item[] }>({
    queryKey: ['bank-select'],
    queryFn: () => fetch(`/api/payments/form/${formId}/banks/sbp`).then(r => r.json()),
  });

  if (!data) {
    return (
      <div className="relative rounded-md h-[34px] border border-brand-3 w-full">
        <Spinner />
      </div>
    );
  }

  return (
    <BankSelector items={data.data} onBankSelect={onBankSelect} />
  );
};

const BankSelector = ({ items, onBankSelect }: {
  items: Item[],
  onBankSelect: PaymentFormBankSelectorProps['onBankSelect']
}) => {
  const ref = useRef<HTMLButtonElement>(null)
  const [selected, setSelected] = useState<Item | null>(null);
  const [query, setQuery] = useState('');

  const filteredItems =
    query === ''
      ? items
      : items.filter((item) =>
        item.value
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, '')),
      );

  const onChange = (item: Item) => {
    setSelected(item);
    item && onBankSelect(item.codeInteger);
  };

  return (
    <Combobox value={selected} onChange={onChange} nullable>
      <div className="relative mt-1">
        <div
          className="relative w-full cursor-default input rounded-md border-brand-3 overflow-hidden p-0 bg-white text-left sm:text-sm">
          <Combobox.Input
            className="w-full border-none py-2 pl-3 pr-10 h-[34px] text-sm leading-5 text-gray-900 outline outline-brand-1"
            displayValue={(item) => (item as Item)?.value}
            onChange={(event) => setQuery(event.target.value)}
            onClick={() => {
              ref?.current && ref.current.click()
            }}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2" ref={ref}>
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="false"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options
            className="absolute mt-1 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
            {!filteredItems.length && query !== '' ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                <Text id="form.text.withdrawal_sbp.nothing_found" />
              </div>
            ) : (
              filteredItems.map((item) => (
                <Combobox.Option
                  key={item.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-brand-1 text-brand-12' : 'text-gray-900'
                    }`
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {item.value}
                        </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-white' : 'text-brand-1'
                          }`}
                        >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
