export const formatPhoneAsUZ = (phoneNumber: string) => {
  if (!phoneNumber.startsWith("+998") || phoneNumber.length !== 13) {
    throw new Error("Invalid phone number format");
  }

  const countryCode = phoneNumber.slice(0, 4); // +998
  const part1 = phoneNumber.slice(4, 6); // 72
  const part2 = phoneNumber.slice(6, 9); // 744
  const part3 = phoneNumber.slice(9, 11); // 45
  const part4 = phoneNumber.slice(11, 13); // 83

  return `${countryCode} ${part1} ${part2} ${part3} ${part4}`;
};
