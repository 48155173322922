import { useQuery } from '@tanstack/react-query';
import { TerminalApi } from '../api';
import type { TerminalMerchantProps } from '../model';

export function useAllTerminals():  Pick<TerminalMerchantProps, 'id' | 'name' | 'paymentFlow'>[] {
  const { data: terminals = [] } = useQuery({
    queryKey: [TerminalApi.KEY],
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: TerminalApi.getTerminals,
  });

  return terminals;
}
