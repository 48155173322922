import noop from 'lodash/noop';
import { createContext } from 'react';
import type { LoginParams } from '../api';

export interface AuthContextValue {
  isAuthorized: boolean;
  signIn(params: LoginParams): void;
  signOut(): void;
}

export const AuthContext = createContext<AuthContextValue>({
  isAuthorized: false,
  signIn: noop,
  signOut: noop,
});
