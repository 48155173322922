import type { FunctionComponent, HTMLAttributes } from 'react';
import cx from 'classnames';

export const Spinner: FunctionComponent<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <div
      role="status"
      className={
        cx(
          'absolute m-auto inset-0 inline-block h-8 w-8 self-center',
          'justify-self-center animate-spin rounded-full',
          'border-4 border-solid border-current border-e-transparent',
          'align-[-0.125em] text-surface text-brand-1',
          props.className,
        )
      }
    />
  );
};