import { Dialog, Transition } from "@headlessui/react";
import type { HTMLAttributes } from "react";
import { Fragment } from "react";
import { Button } from "../atoms";

export const ConfirmDialog = ({
  isOpen,
  title,
  paragraph,
  onContinue,
  onClick,
}: {
  isOpen: boolean;
  title: string;
  paragraph: string;
  onContinue: () => void;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      {/* @ts-expect-error */}
      <Dialog as="div" className="relative z-[100]" onClose={onClick}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[400px] max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{paragraph}</p>
                </div>

                <div className="mt-4 flex gap-2">
                  <Button onClick={onContinue}>Continue</Button>
                  {/* @ts-expect-error */}
                  <Button onClick={onClick}>Cancel</Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
