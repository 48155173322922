import { useMutation, useQuery } from '@tanstack/react-query';
import { type FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { BalanceReportParams, ReferenceApi, ReportApi } from '../../api';
import { useMerchant, useProfile } from '../../hooks';
import { formatDateForInput } from '../../utils';
import { useTerminalStore } from '../../store';

export const BalanceReportCard: FC = () => {
  const merchant = useMerchant();
  const profile = useProfile();
  const { terminalId } = useTerminalStore();

  const { data: currencyList = [] } = useQuery({
    queryKey: [ReferenceApi.KEY, 'currency'],
    queryFn: ReferenceApi.currency,
  });
  const { handleSubmit, register, watch } = useForm<BalanceReportParams>();
  const periodStart = watch('periodStart');
  const periodEnd = watch('periodEnd');

  const { mutate } = useMutation({
    mutationFn: ReportApi.balance,
  });

  const onSubmit = useCallback(
    (data: BalanceReportParams) => {
      if (merchant) {
        mutate({
          ...data,
          terminalId,
        });
      }
    },
    [merchant, terminalId, mutate],
  );

  if (!profile || !profile.permissions.includes('report.read.balance')) {
    return null;
  }

  return (
    <li className="p-4 rounded-2xl shadow-1 bg-white">
      <h2 className="mb-5 text-lg font-bold text-center">Balance</h2>
      <form className="grid gap-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-4">
          <label>
            <span className="mb-1 block text-brand-2 text-sm pl-4">Date From</span>
            <input
              {...register('periodStart', {
                required: true,
                valueAsDate: true,
              })}
              className="input w-full"
              max={formatDateForInput(new Date(periodEnd))}
              type="date"
            />
          </label>
          <label>
            <span className="mb-1 block text-brand-2 text-sm pl-4">Date To</span>
            <input
              {...register('periodEnd', {
                required: true,
                valueAsDate: true,
              })}
              className="input w-full"
              min={formatDateForInput(new Date(periodStart))}
              type="date"
            />
          </label>

          <label>
            <span className="mb-1 block text-brand-2 text-sm pl-4">Currency</span>
            <select
              {...register('currencyId', {
                required: true,
                valueAsNumber: true,
              })}
              className="input appearance-none w-full"
            >
              <option value="">Not selected</option>
              {currencyList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </label>
        </div>

        <button className="button button--block button--primary" type="submit">
          Get report
        </button>
      </form>
    </li>
  );
};
